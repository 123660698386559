import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";

function DataModal({
  showDataModal,
  setShowDataModal,
  addData,
  graphType,
  selectGraphIndex,
}) {
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const handleChange = (value, index, key) => {
    const curr = graphData;

    curr[index][key] = value;
    setGraphData([...curr]);
  };
  const addNewPoint = () => {
    switch (graphType) {
      case "pie":
        setGraphData((p) => {
          return [...p, { label: "", value: "" }];
        });
        break;
      case "barline":
        setGraphData((p) => {
          return [...p, { label: "", volume: "", value: "" }];
        });
        break;

      default:
        break;
    }
  };
  const removePoint = (index) => {
    let curr = graphData;
    curr = curr.filter((item, i) => i !== index);
    setGraphData([...curr]);
  };
  return (
    <Modal
      className="UploadModal"
      width={500}
      visible={showDataModal}
      onCancel={() => setShowDataModal(false)}
      footer={[
        <Button
          type="primary"
          style={{
            backgroundColor: "gray",
            borderColor: "gray",
          }}
          onClick={() => setShowDataModal(false)}
          disabled={loading}
        >
          Cancel
        </Button>,
        <Button
          loading={loading}
          type="primary"
          onClick={() => addData(graphData)}
          disabled={loading}
        >
          Save
        </Button>,
      ]}
    >
      <h4>data upload for {graphType} Graph</h4>
      <Button onClick={() => addNewPoint()}>Add new</Button>

      <>
        {" "}
        {graphData.map((item, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                padding: "8px 0px",
                gap: "5px",
                flexWrap: "wrap",
                borderBottom: "1px solid #0000003d",
                margin: "5px 0px",
              }}
            >
              {Object.entries(item).map(([k, val], j) => {
                return (
                  <div
                    style={{
                      display: "flex",

                      gap: "5px",
                      fontWeight: "bold",
                    }}
                    key={j}
                  >
                    <label>{k}</label>{" "}
                    <input
                      type={k === "value" || k === "volume" ? "number" : "text"}
                      onChange={(e) => handleChange(e.target.value, i, k)}
                      value={val}
                    />
                  </div>
                );
              })}

              <Button onClick={() => removePoint(i)}>
                <DeleteOutlined color="red" />
              </Button>
            </div>
          );
        })}
      </>
    </Modal>
  );
}

export default DataModal;
