import { Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import configApi from "../../../config/Api";
import ShowToc from "./ShowToc";
const { v4: uuidv4 } = require("uuid");

const { Option } = Select;
function getToken() {
  return window.localStorage.getItem("token");
}
function AttachReport({ modalData, setModalData, customFlag }) {
  const [hubData, setHubData] = React.useState([]);
  const [reportList, setReportList] = useState([]);
  const [toc, setSetToc] = useState({});
  const [loading, setLoading] = useState(true);
  const base_url = configApi.api.base_url;
  const getHubList = async () => {
    setLoading(true);
    if (hubData.length == 0) {
      let full_access = false;
      await axios
        .get(
          `${configApi.api.base_url}/api/hubs/list?full_access=${full_access}`
        )
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const getReportList = async () => {
    const token = getToken();
    let response = null;
    if (customFlag) {
      response = await axios.get(
        `${base_url}/api/custom/report?hub_id=${modalData.hub_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      response = await axios.get(
        `${base_url}/api/comments/get-reports?hub_id=${modalData.hub_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    response && setReportList(response.data);
  };
  useEffect(() => {
    if (modalData.hub_id) {
      getReportList();
    }
  }, [modalData.hub_id]);

  useEffect(() => {
    if (modalData.report_id) {
      getReportTOC();
    }
  }, [modalData.report_id]);
  const getReportTOC = async () => {
    let resp = null;
    if (customFlag) {
      resp = await axios.get(
        `${base_url}/api/custom/slide-customReportToc?custom_report_id=${modalData.report_id}`
      );
      resp && setSetToc(resp?.data?.data || []);
    } else {
      resp = await axios.get(
        `${base_url}/api/custom/slide-reportToc?report_id=${modalData.report_id}`
      );
      resp && setSetToc(resp?.data?.data || []);
    }
  };
  const tocFunction = (action, depth, childIndex, depthTree, info) => {
    // console.log(action, depth, childIndex, depthTree, info);
    const section_id = uuidv4();

    switch (action) {
      case "addBelow":
        setModalData((p) => {
          return {
            ...p,
            meta: {
              section_id,
            },
            tocInfo: {
              depth,
              childIndex,
              depthTree,
              title: info,
            },
            section_id: `${section_id}-customSlide`,
          };
        });

        break;
      case "addChild":
        setModalData((p) => {
          return {
            ...p,
            tocInfo: {
              depth,
              childIndex,
              depthTree,
              title: info,

              isChild: true,
            },
            section_id: `${section_id}-customSlide`,
            meta: {
              section_id,
            },
          };
        });
        break;
      case "remove":
        setModalData((p) => {
          return {
            ...p,
            tocInfo: null,
          };
        });
        break;
      default:
        break;
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
        {/* Select Hubs */}
        <Select
          showSearch
          placeholder="Select Hubs"
          style={{ width: "40%" }}
          onFocus={() => (hubData.length === 0 ? getHubList() : null)}
          onChange={(value) => {
            const selectedHubObject = hubData.find((item) => item.id === value);
            setModalData((p) => {
              return {
                ...p,
                hub_id: selectedHubObject ? selectedHubObject.id : null,
              };
            });
          }}
          value={modalData.hub_id}
          loading={loading}
          filterOption={(input, option) =>
            (option.props.children ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }>
          {hubData.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>

        {/* Select Reports */}
        <Select
          showSearch
          placeholder={`Select ${customFlag && "Custom"} Reports`}
          style={{ width: "60%" }}
          onChange={(value) => {
            setModalData((p) => {
              return { ...p, report_id: value };
            });
          }}
          value={modalData.report_id}
          filterOption={(input, option) =>
            (option.props.children ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }>
          {reportList.map((item) => (
            <Option
              key={item._id}
              value={customFlag ? item._id : item.report_id}>
              {customFlag ? item.variant_name : item.title}
            </Option>
          ))}
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "11px",
          margin: " 20px 5px",
          fontWeight: "bold",
        }}>
        <div style={{ width: "20%" }}>
          <label>page No</label>
          <div>
            <input
              value={Number(modalData.pageNo || 0)}
              onChange={(e) =>
                setModalData((p) => {
                  return { ...p, pageNo: Number(e.target.value) };
                })
              }
            />
          </div>
        </div>
        <div style={{ width: "70%" }}>
          <label>Slide Heading</label>
          <div>
            <input
              style={{ width: "100%" }}
              value={modalData.slideHeading}
              onChange={(e) =>
                setModalData((p) => {
                  return { ...p, slideHeading: e.target.value };
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "11px",
          margin: " 20px 5px",
          fontWeight: "bold",
        }}>
        <input
          checked={modalData.tocInfo ? true : false}
          type="checkbox"
          onClick={() =>
            setModalData((p) => {
              return {
                ...p,
                tocInfo: p.tocInfo ? null : {},
              };
            })
          }
        />
        <label>Show in TOC</label>
      </div>
      <div>
        {modalData.report_id &&
          toc &&
          (toc[0]?.text_objects || toc[0]?.data) &&
          modalData.tocInfo && (
            <ShowToc
              depthTree={""}
              customSlide={modalData}
              tocFunction={tocFunction}
              childIndex={0}
              data={customFlag ? toc[0]?.data : toc[0]?.text_objects}
              pad={5}
              depth={0}
            />
          )}
      </div>
    </div>
  );
}

export default AttachReport;
