import React, { Component } from "react";
import styled from "styled-components";
import { Button, notification, Select, Table, Switch, Tree } from "antd";
import { DownOutlined, InboxOutlined } from "@ant-design/icons";
import { BulkUploadComponentWrapper } from "./BulkUpload.style";
import config from "../../config/Api";
import axios from "axios";
import Dragger from "antd/lib/upload/Dragger";

const Option = Select.Option;
const openNotification = (msg) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Sheet upload successful.",
      duration: 0,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to upload sheet.",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};
const formData = new FormData();

const CustomTable = styled(Table)`
  .ant-table-header {
    background-color: #f0f0f0;
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    color: #333;
  }

  .ant-table-tbody > tr:hover > td {
    background: #fff;
  }

  .ant-table-tbody > tr {
    background-color: #ffebeb;
  }

  .ant-table-tbody > tr:nth-child(even) {
    background-color: #f8f6f6;
  }
`;

class UploadSheetComponent extends Component {
  state = {
    onGoingApiCall: false,
    selectedHub: null,
    loading: false,
    hubData: [],
    toShowModal: false,
    toShowErrors: false,
    branches: [],
    expandAllBranches: false,
    uploadedSheet: [],
    errors: null,
    infoTabVis: false,
    validate_value: false,
    sheet_error_data: [],
  };

  toggleShowModal = () => {
    this.setState({ toShowModal: !this.state.toShowModal });
  };

  toggleInfoTab = () => {
    this.setState({ infoTabVis: !this.state.infoTabVis });
  };

  UploadSheet = async () => {
    try {
      this.setState({
        onGoingApiCall: true,
        errors: null,
        sheet_error_data: [],
      });
      if (this.state.uploadedSheet && this.state.uploadedSheet[0]) {
        if (
          //!this.state.selectedHub
          this.state.selectedHub === "Select Hub" ||
          this.state.selectedHub === null
        ) {
          this.setState({ onGoingApiCall: false });
          return openNotification("Please select Hub to continue");
        }

        await axios
          .get(`${config.api.base_url}/api/hubs/${this.state.selectedHub}`)
          .then((response) => {
            if (response.status === 200 && response.data && response.data.obj) {
              let hubData = response.data.obj;
              formData.set("file", this.state.uploadedSheet[0].originFileObj);
              formData.set("name", hubData.name);
              formData.set("base_year", hubData.baseYear);
              formData.set("start_year", hubData.startYear);
              formData.set("end_year", hubData.endYear);
              formData.set("id", hubData.id);
              formData.set("validate_value", this.state.validate_value);
            } else throw "No Data";
          })
          .catch((error) => {
            openNotification(error?.response?.data?.message || false);
            this.setState({ onGoingApiCall: false });
          });

        axios
          .post(`${config.api.base_url}/api/parse-excel`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            openNotification(true);
            this.setState({
              onGoingApiCall: false,
              errors: null,
              // toShowModal: false,
              // toShowErrors: false,
              // uploadedSheet: [],
            });
            if (response?.data?.error_arr?.length) {
              let finalArr = [];
              //eslint-disable-next-line
              response?.data?.error_arr?.forEach((item) => {
                if (!finalArr[item.counter - 1])
                  finalArr[item.counter - 1] = {};
                finalArr[item.counter - 1] = {
                  ...finalArr[item.counter - 1],
                  ...item,
                };
              });
              this.setState({
                sheet_error_data: finalArr,
              });
            }
            //    this.closeModal();
          })
          .catch((error) => {
            openNotification(error?.response?.data?.message || false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.length > 0
            ) {
              let errorArray = error.response.data,
                ItemCount,
                uniqueKey = 0,
                errorTree = [],
                branches = [];

              for (let errors of errorArray) {
                uniqueKey++;
                ItemCount = 0;

                if (errorTree)
                  errorTree.push({
                    title: errors[0],
                    key: `Tree ${uniqueKey}`,
                    children: [],
                  });
                branches.push(`Tree ${uniqueKey}`);

                if (errors.length > 0)
                  for (let error of errors) {
                    if (ItemCount)
                      errorTree[errorTree.length - 1].children.push({
                        title: error,
                        key: uniqueKey,
                      });
                    ItemCount++;
                    uniqueKey++;
                  }
                else errorTree = null;
              }

              this.setState({ branches: branches });
              this.setState({ errors: errorTree });
            }
            this.setState({ onGoingApiCall: false });
            openNotification(false);
          });
      } else {
        this.setState({ onGoingApiCall: false });
        openNotification("Please upload file to continue.");
      }
    } catch (e) {
      this.setState({ onGoingApiCall: false });
      openNotification("Sorry! Something went wrong.");
    }
  };

  getHubList = async () => {
    this.setState({ loading: true });
    if (this.state.hubData.length === 0) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ hubData: response.data.obj });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          openNotification(false);
          this.setState({ loading: false });
        });
    }
  };

  toTitleCase = (str) => {
    return (str + "").toUpperCase();
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  closeModal = () => {
    this.setState({
      onGoingApiCall: false,
      toShowModal: false,
      errors: null,
      toShowErrors: false,
      uploadedSheet: [],
      selectedHub: "Select Hub",
    });
  };

  //Fetch list of report for upload sample
  fetchReports = async (value) => {
    this.setState({
      reports: {
        loading: true,
        list: [],
      },
    });
    try {
      let url = config.api.base_url;
      let response = await axios.get(`${url}/api/searchReport`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          title: value,
          pageNo: "1",
        },
      });

      const data = Array.isArray(response.data)
        ? response.data.map((report) => ({
            title: report.title,
            id: report.report_id,
          }))
        : [];
      this.setState({ reports: { loading: false, list: data } });
    } catch (error) {
      this.setState({
        reportFile: null,
        selected_reports: [],
        value: "search for reports",
      });
      notification.error({
        message: "Error",
        description: "Something went wrong!!!",
      });
    }
  };

  //   On select of a particular report
  onReportsChange = (value) => {
    if (value) this.setState({ value: value });
  };

  // Final form upload after uploading of csv or selection of reports
  handleUploadSample = () => {
    let { selected_reports, reportFile, uploadFileList } = this.state;

    if (uploadFileList && uploadFileList.length > 0) {
      let formData = new FormData();
      uploadFileList.map((file) => {
        return Object.keys(file).map((key) => {
          return formData.append(key, file[key]);
        });
      });

      this.setState({
        uploadLoader: true,
      });
      //API END POINT
      axios({
        method: "post",
        url: `${config.api.base_url}/api/upload_sample_ms`,
        data: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              uploadLoader: false,
              uploadFileList: [],
            });
            notification.success({
              message: "Success",
              description: response.data && response.data.message,
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          notification.error({
            message: "Error",
            description: "Error while uploading sample",
          });
        });
    }
  };

  // Prevent post api call on file change in the upload dragger
  dummyRequest = ({ file, onSuccess }) => {
    if (file.size < 100000000) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    } else onSuccess("removed");
  };

  handleChange = ({ file }) => {
    if (file.size < 100000) {
      this.setState({
        reportFile: file.originFileObj,
      });
    }
  };

  handleAddUploadElements = () => {
    const { reportFile, value, uploadFileList } = this.state;
    let tempElementList = [];
    if (reportFile && value) {
      tempElementList = [...uploadFileList, { [value]: reportFile }];
      this.setState(
        {
          uploadFileList: tempElementList,
        },
        () => {
          this.setState({ reportFile: null, value: "" });
        }
      );
    }
  };

  handleRemoveUploadElements = (index) => {
    const { uploadFileList } = this.state;
    let tempElementList = uploadFileList;
    tempElementList.splice(index, 1);
    this.setState({
      uploadFileList: tempElementList,
    });
  };

  handleDisableUploadButton = () => {
    const { reportFile, value, uploadFileList } = this.state;
    if (uploadFileList && uploadFileList.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  disableAddButton = () => {
    const { reportFile, value, uploadFileList } = this.state;
    if (reportFile && value) {
      return false;
    } else {
      return true;
    }
  };

  createErrorSheetColumn = (data) => {
    try {
      let column = [
        {
          title: "Row",
          dataIndex: "counter",
          key: "counter",
        },
      ];

      data.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (!column.some((item) => item.key == key)) {
            column.push({
              title: key,
              dataIndex: key,
              key: key,
            });
          }
        });
      });

      return column;
    } catch (error) {
      notification.error({
        message: "Something went wrong.",
      });
      console.log("Error Ocurred", error);
    }
  };

  render() {
    const { uploadFileList } = this.state;
    const preventRequest = () => false;

    //Upload file configuration
    const props = {
      multiple: false,
      customRequest: this.dummyRequest,
      onRemove: (file) => {
        this.setState((state) => {
          return {
            reportFile: {},
          };
        });
      },
      beforeUpload: (file) => {
        if (file.size < 100000000) {
          this.setState((state) => ({
            reportFile: file,
          }));
        } else {
          notification.error({
            message: "Error!",
            duration: 2,
            description: "Please Upload Something Under 100KB",
          });
          return false;
        }
      },
    };
    return (
      <BulkUploadComponentWrapper>
        <div style={{ marginTop: "6rem" }} className="card">
          <div
            className="leftContainer"
            style={{
              width: this.state.infoTabVis ? "70%" : "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Button
              onClick={() => {
                //Go Back
                window.history.back();
              }}
              style={{
                marginLeft: "3rem",
                // flexBasis: "10%",
                marginRight: "1rem",
              }}
            >
              Go Back <ArrowLeftOutlined />
            </Button> */}
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  marginTop: "20px",
                }}
              >
                Upload Sheet
              </h2>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Dragger
                disabled={this.state.onGoingApiCall}
                style={{ width: "40%", margin: "auto" }}
                accept=".csv, .xlsx"
                maxCount={1}
                fileList={this.state.uploadedSheet}
                // showUploadList={false}
                onChange={(e) => {
                  this.setState({ uploadedSheet: e.fileList });
                }}
                beforeUpload={preventRequest}
                //customRequest={() => {}}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Only .CSV or .XLSX file types</p>
              </Dragger>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "50px",
                  marginTop: "40px",
                }}
              >
                <Select
                  disabled={this.state.onGoingApiCall}
                  style={{ display: "block", width: "200px" }}
                  id="SelectHub"
                  placeholder="Select Hub"
                  defaultValue="Select Hub"
                  //{!this.state.toShowModal ? "Select Hub" : null}
                  value={this.state.selectedHub}
                  loading={this.state.loading}
                  onFocus={() =>
                    this.state.hubData.length === 0 ? this.getHubList() : null
                  }
                  maxLength=""
                  onSelect={(e) => this.setState({ selectedHub: e })}
                >
                  {this?.state?.hubData?.map((item, index) => {
                    // if (item.name !== "ZEROHUB")
                    return (
                      <Option key={item.id} value={item.id}>
                        {this.toTitleCase(item.name)}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              <Button
                disabled={this.state.onGoingApiCall}
                key="View Upload Errors"
                type="danger"
                onClick={() =>
                  this.setState({
                    toShowErrors: !this.state.toShowErrors,
                    toShowModal: false,
                    expandAllBranches: false,
                  })
                }
                hidden={this.state.errors ? false : true}
              >
                {this.state.toShowErrors ? "Hide" : "Show"} Upload Errors
              </Button>
              <Button
                key="Upload Sheet"
                type="primary"
                onClick={() => this.UploadSheet()}
                loading={this.state.onGoingApiCall}
              >
                {this.state.onGoingApiCall ? "Uploading" : "Upload Sheet"}
              </Button>
            </div>
            {this.state.toShowErrors && (
              <div
                style={{
                  maxHeight: "480px",
                  overflowY: "auto",
                  marginTop: "10px",
                }}
              >
                <Tree
                  showLine
                  switcherIcon={<DownOutlined />}
                  treeData={this.state.errors}
                />
              </div>
            )}
            {this.state.sheet_error_data?.length ? (
              <div className="errorTableContainer">
                <h3>
                  Incorrect dimension Values were found in below mentioned rows.
                </h3>
                <h3>Please check and re upload below mentioned data.</h3>
                <CustomTable
                  style={{
                    width: "95%",
                    overflow: "auto",
                    margin: "auto",
                    textTransform: "capitalize",
                  }}
                  columns={this.createErrorSheetColumn(
                    this.state.sheet_error_data
                  )}
                  dataSource={this.state.sheet_error_data}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={this.state.infoTabVis ? "infoContainer" : "info"}>
            <Button
              type="default"
              danger={this.state.infoTabVis}
              onClick={() => this.toggleInfoTab()}
            >
              {this.state.infoTabVis ? "Close Guide" : "Open Guide"}
            </Button>
            {this.state.infoTabVis && (
              <div className="infoContent">Documentations coming soon...</div>
            )}
          </div>
        </div>
      </BulkUploadComponentWrapper>
    );
  }
}

export default UploadSheetComponent;
