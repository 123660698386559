import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../config/Api";
import {
  Input,
  Select,
  Spin,
  Tooltip,
  Button,
  notification,
  Table,
} from "antd";
import {
  MarketActivityContainer,
  MarketActivityHeader,
  // ReporstList,
  // ReportCard,
  SpinBox,
  // ViewMore,
  ModalContainer,
  FullPageLoader,
  // Label,
} from "./styles";
// import Published from "../../svg/Published.svg";
// import NotPublished from "../../svg/NotPublished.svg";
import UpdateMeta from "./UpdateMeta";
import PublishRD from "./PublishRD";

import { setLinkCounter } from "../../reducersAndActions/Actions";
import { connect } from "react-redux";
import InterfaceHeader from "../InterfaceHeader";
import moment from "moment";
// import { ArrowLeftOutlined } from "@ant-design/icons";
const base_url = config.api.base_url;
const website_url = config.api.website_url;
// const preview_url = config.api.express_url;
const { Search } = Input;
const { Option } = Select;

function MarketActivities(props) {
  // States & Refs
  let [hubList, setHubList] = useState([]);
  let [hubValue, setHubValue] = useState(null);
  let [rdList, setRdList] = useState([]);
  let [filteredRDList, setFilteredRDList] = useState([]);
  // let [currentRDList, setCurrentRDList] = useState([]);
  let [loading, setLoading] = useState(false);
  let [fullPageLoading, setFullPageLoading] = useState(false);
  let [isModalOpen, setIsModalOpen] = useState([]);
  let [selectedReport, setSelectedReport] = useState({});
  let [searchText, setSearchText] = useState(null);

  let selectRef = useRef();
  let searchRef = useRef();

  let config = {
    method: "post",
    url: `${base_url}/api/previewRD`,
    //url:"https://www.mordorintelligence.com/preview",
    data: {},
  };

  // Initial API calls and State Setting
  useEffect(async () => {
    setLoading(true);
    let full_access = false;
    axios
      .get(`${base_url}/api/hubs/list?full_access=${full_access}`)
      .then((response) => {
        if (response.status === 200) {
          let hubs = [...response.data.obj].filter((hub) => {
            return hub.name.toLowerCase() !== "zero";
          });
          setHubList(hubs);
        }
      })
      .catch((error) => {
        let args = {
          message: error,
          description: "Hub List Not Found.",
          duration: 1,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
      });

    // axios
    //   .get(`${base_url}/api/interfaceQueries/get-marketing-activites`)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       let arr = [...response.data];
    //       setRdList([...arr]);
    //       setFilteredRDList([...arr]);
    //       // setCurrentRDList([...currRD]);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, []);

  //  Filter And Search Functionalities
  let expandRDList = () => {
    // let currentLength = currentRDList.length;
    // let newCurrRD = filteredRDList.slice(0, currentLength + 10);
    // setCurrentRDList([...newCurrRD]);
  };

  const fetchAllRD = async (value) => {
    axios
      .get(
        `${base_url}/api/interfaceQueries/get-marketing-activites?hub_id=${value}`
      )
      .then((response) => {
        if (response.status === 200) {
          let arr = [...response.data];
          setRdList([...arr]);
          setFilteredRDList([...arr]);
          // setCurrentRDList([...currRD]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let hubChange = (value) => {
    setHubValue(value);
    setLoading(true);
    fetchAllRD(value);
  };

  let onclickOfSearch = (event) => {
    let value = event.target.value?.trim();
    setSearchText(value);
    let newFilteredRD = rdList.filter((rd) => {
      return rd.title.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredRDList([...newFilteredRD]);
    // let newCurrentRD = filteredRDList.slice(0, 10);
    // setCurrentRDList([...newCurrentRD]);
  };

  let resetList = () => {
    setLoading(true);
    setHubValue(null);
    setFilteredRDList([]);
    setSearchText(null);
    // axios
    //   .get(`${base_url}/api/interfaceQueries/get-marketing-activites`)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       let arr = [...response.data];
    //       setRdList([...arr]);
    //       setFilteredRDList([...arr]);
    //       // setCurrentRDList([...currRD]);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // Report Functions
  let updateMeta = (e, rd) => {
    setSelectedReport({ ...rd });
    setIsModalOpen(["updatemeta"]);
  };

  let publishToWebsite = (e, rd) => {
    setSelectedReport({ ...rd });
    setIsModalOpen(["publishrd"]);
  };

  let previewRD = async (e, rd) => {
    setSelectedReport({ ...rd });
    setFullPageLoading(true);
    await axios
      .get(
        `${base_url}/api/interfaceQueries/getRdList?specificRd=true&id=${rd.id}`
      )
      .then((response) => {
        if (response.status === 200) {
          let rdData = {
            template: "lubricants-template",
            report_data: {
              report_data: {
                ...response.data,
                // template: "lubricants-template",
                _id: {
                  $oid: response.data["_id"],
                },
              },
            },
          };
          config.data = rdData;
          let args = {
            message: "RD Data Found",
            duration: 2.5,
            placement: "topRight",
          };
          notification.success(args);
        }
      })
      .catch((error) => {
        let args = {
          message: "Can Not Find RD Data",
          duration: 2,
          placement: "topRight",
        };
        setFullPageLoading(false);
        notification.error(args);
        console.log(error);
      });

    await axios(config)
      .then((response) => {
        if (response.status === 200) {
          let args = {
            message: "RD Preview Is Ready",
            duration: 2.5,
            placement: "topRight",
          };
          notification.success(args);
          setFullPageLoading(false);
          if (response.data.data) {
            let newWindow = window.open();
            newWindow.document.write(response.data.data);
          }
        }
      })
      .catch((error) => {
        let args = {
          message: "RD Preview Not Found",
          duration: 2,
          placement: "topRight",
        };
        setFullPageLoading(false);
        notification.error(args);
        console.log(error);
      });
  };

  let colums = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "User",
      dataIndex: "send_to_marketing_user",
      key: "send_to_marketing_user",
      render: (data) => (
        <Tooltip title={data}>
          <span>
            {data?.split("@")[0].charAt(0).toUpperCase() +
              data?.split("@")[0].slice(1)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Published On",
      dataIndex: "publish_timestamp",
      key: "publish_timestamp",
      // moment(data).format("YYYY-MM-DD HH:mm:ss")

      render: (data) => moment(data).format("lll"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, data) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}>
            <Tooltip title={"Preiew RD Page Of Report"}>
              <Button type="default" onClick={(e) => previewRD(e, data)}>
                Preview RD
              </Button>
            </Tooltip>
            {props.USER_DATA.obj.rd_publish_access && (
              <>
                <Tooltip title={"Update Meta Of The Report"}>
                  <Button type="default" onClick={(e) => updateMeta(e, data)}>
                    Update Meta
                  </Button>
                </Tooltip>

                <Tooltip title={"Publish Report To Website"}>
                  <Button
                    type="default"
                    onClick={(e) => publishToWebsite(e, data)}>
                    Publish
                  </Button>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Published Status",
      dataIndex: "published-status",
      key: "published-status",
      render: (_, data) => {
        let flag = data.is_published === true && data.active === true;
        return (
          <Tooltip title={flag ? "Already Published" : "Not Published Yet"}>
            <a
              target={"_blank"}
              href={`${website_url}industry-reports/${data.slug}`}>
              <Button type={flag ? "primary" : "default"} danger={flag}>
                Published
              </Button>
            </a>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <InterfaceHeader />

      <MarketActivityContainer>
        <MarketActivityHeader>
          <div>
            <Select
              value={hubValue}
              ref={selectRef}
              style={{ width: 350, marginRight: "10px" }}
              onSelect={hubChange}
              autoFocus
              placeholder={"Select Hub Name"}>
              {hubList.length &&
                hubList.map((hub) => {
                  return (
                    <>
                      <Option value={`${hub.id}`}>
                        {hub.name.toUpperCase()}
                      </Option>
                    </>
                  );
                })}
            </Select>
            {hubValue ? (
              <Input
                placeholder="Type Title To Search"
                onInput={onclickOfSearch}
                style={{ width: 350 }}
              />
            ) : null}
          </div>
          <Button onClick={resetList} style={{ color: "gray" }}>
            Reset
          </Button>
        </MarketActivityHeader>

        {!hubValue ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "80px",
              fontSize: "34px",
            }}>
            <strong>Please select a hub to continue</strong>
          </div>
        ) : loading ? (
          <SpinBox>
            <Spin />
          </SpinBox>
        ) : (
          <>
            <Table
              columns={colums}
              dataSource={searchText ? filteredRDList : rdList}
            />

            {/* <ReporstList>
              {!currentRDList.length ? (
                <ReportCard
                  style={{
                    justifyContent: "center",
                    alignitems: "center",
                    paddingTop: "1.5rem",
                    fontSize: "1.1rem",
                    color: "#46cdcf",
                  }}>
                  No Report
                </ReportCard>
              ) : (
                currentRDList.map((rd) => {
                  let flag = false;
                  if (rd.is_published && rd.is_published === true) flag = true;
                  if (rd.active && rd.active === true) flag = true;

                  return (
                    <ReportCard>
                      <div>
                        <h4>{rd?.title} </h4>
                      </div>

                      <div>
                        <div
                          style={{
                            borderLeft: "1px solid #46cdcf",
                            borderRight: "1px solid #46cdcf",
                            padding: "0 15px",
                          }}>
                          User:{" "}
                          <Tooltip title={rd?.send_to_marketing_user}>
                            <span>
                              {rd?.send_to_marketing_user
                                .split("@")[0]
                                .charAt(0)
                                .toUpperCase() +
                                rd?.send_to_marketing_user
                                  .split("@")[0]
                                  .slice(1)}
                            </span>
                          </Tooltip>
                          <br />
                          On:{" "}
                          <span>
                            {new Date(rd?.publish_timestamp).toLocaleString()}
                          </span>
                        </div>

                        <Tooltip title={"Preiew RD Page Of Report"}>
                          <button onClick={(e) => previewRD(e, rd)}>
                            Preview RD
                          </button>
                        </Tooltip>
                        {props.USER_DATA.obj.rd_publish_access && (
                          <>
                            <Tooltip title={"Update Meta Of The Report"}>
                              <button onClick={(e) => updateMeta(e, rd)}>
                                Update Meta
                              </button>
                            </Tooltip>

                            <Tooltip title={"Publish Report To Website"}>
                              <button onClick={(e) => publishToWebsite(e, rd)}>
                                Publish
                              </button>
                            </Tooltip>
                          </>
                        )}

                        {flag && (
                          <Tooltip
                            title={
                              flag ? "Already Published" : "Not Published Yet"
                            }>
                            <a
                              target={"_blank"}
                              href={`${website_url}industry-reports/${rd?.slug}`}>
                              <Label>Published</Label>
                            </a>
                          </Tooltip>
                        )}
                      </div>
                    </ReportCard>
                  );
                })
              )}
            </ReporstList> */}
          </>
        )}

        {/* {currentRDList.length < filteredRDList.length && (
          <ViewMore>
            <button onClick={expandRDList}>View More</button>
          </ViewMore>
        )} */}

        {isModalOpen && isModalOpen.length !== 0 && (
          <ModalContainer>
            {isModalOpen.includes("updatemeta") && (
              <UpdateMeta
                selectedRD={selectedReport}
                set={(response) => {
                  setIsModalOpen(response);
                }}
              />
            )}
            {isModalOpen.includes("publishrd") && (
              <PublishRD
                fetchAllRD={fetchAllRD}
                selectedRD={selectedReport}
                set={(response) => {
                  setIsModalOpen(response);
                }}
              />
            )}
          </ModalContainer>
        )}

        {fullPageLoading && (
          <FullPageLoader>
            <Spin />
          </FullPageLoader>
        )}
      </MarketActivityContainer>
    </>
  );
}

function mapStateToPros(state) {
  return {
    USER_DATA: state.USER_DATA,
  };
}

const mapDispatchToProps = {
  setLinkCounter,
};

export default connect(mapStateToPros, mapDispatchToProps)(MarketActivities);
