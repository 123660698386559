import React from "react";
import Heading from "../../../Heading";
import Graph from "../../../../Graphs/Graphs";
import Subheading from "../../../Subheading";
import { Container, Row, FullColumn } from "../style";
import Analysis from "../../../Analysis";
function FinalCusTemTwo(props) {
  const { data } = props;
  return (
    <Container style={{ height: "620px" }} formInterface={true}>
      <Heading heading={data.slideHeading} />
      <FullColumn>
        <Subheading subheading={data.analysis.heading} />
        <Analysis
          trends={data.analysis.data}
          edit_mode={false}
          section_id={
            data.section_id
              ? data.section_id
              : data.meta.section_id
                ? data.meta.section_id
                : data.analysis.heading
          }
        />
      </FullColumn>
    </Container>
  );
}

export default FinalCusTemTwo;
