import { Button, Modal, notification, Switch } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config/Api";
import loader from "../../images/spinner.gif";
import deleteIcon from "../../images/delete.png";

const showKeysButDisableEdit = ["data", "url"];
const dontShowKeys = [
  "hub_id",
  "meta",
  "template",
  "section_id",
  "parent_id",
  "current_stage",
  "template_name",
  "unique_id",
  "position",
  "type",
  "counter",
  "referenced_analysis",
  "access",
  "startAtAnalysisCounter",
  "dimensions",
  "editted",
];

const EditableObject = ({ data, onChange }) => {
  const handleInputChange = (key, value) => {
    onChange({ ...data, [key]: value });
  };

  const renderValue = (key, value) => {
    if (dontShowKeys.includes(key)) {
      return null;
    }

    if (typeof value === "string" || typeof value === "number") {
      if (showKeysButDisableEdit.includes(key)) {
        return <div>{value}</div>;
      }
      return (
        <input
          type={typeof value === "number" ? "number" : "text"}
          value={value}
          onChange={(e) => handleInputChange(key, e.target.value)}
          style={{ width: "100%", marginBottom: "10px" }}
        />
      );
    } else if (Array.isArray(value)) {
      return (
        <div style={{ marginLeft: "20px" }}>
          {value.map((item, index) => (
            <div key={index} style={{ marginBottom: "5px" }}>
              {typeof item === "string" || typeof item === "number" ? (
                <input
                  type={typeof item === "number" ? "number" : "text"}
                  value={item}
                  onChange={(e) => {
                    const newArray = [...value];
                    newArray[index] = e.target.value;
                    handleInputChange(key, newArray);
                  }}
                  style={{ width: "100%" }}
                />
              ) : (
                <EditableObject
                  data={item}
                  onChange={(updatedValue) => {
                    const newArray = [...value];
                    newArray[index] = updatedValue;
                    handleInputChange(key, newArray);
                  }}
                />
              )}
            </div>
          ))}
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <div style={{ marginLeft: "20px" }}>
          <EditableObject
            data={value}
            onChange={(updatedValue) => handleInputChange(key, updatedValue)}
          />
        </div>
      );
    } else {
      return <div>{String(value)}</div>;
    }
  };

  if (!data) {
    return null;
  }

  return (
    <div
      style={{ padding: "10px", border: "1px solid #ccc", margin: "10px 0" }}>
      {Object.entries(data).map(
        ([key, value]) =>
          !dontShowKeys.includes(key) && (
            <div key={key}>
              <strong>{key}:</strong>
              {renderValue(key, value)}
            </div>
          )
      )}
    </div>
  );
};

function EditCustomReportModal({
  openEditModal,
  setOpenEditModal,
  reportId,
  hub_id,
}) {
  const [data, setData] = useState(null);

  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionData, setSectionData] = useState(null);

  const [loading, setLoading] = useState(false);
  let url = config.api.base_url;

  const fetchReportData = async () => {
    try {
      setLoading(true);
      let reportData = await axios.get(`${url}/api/custom/report/${reportId}`);

      if (reportData && Object.keys(reportData || {}).length) {
        setData(reportData.data.data.data);
        notification.success("Report data fetched successfully");
      }
    } catch (error) {
      console.log("ERROR OCCURED IN fetchReportData", error);
      notification.error("Error Occured while fetching data");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (reportId) {
      fetchReportData();
    }
  }, [reportId]);

  const closeModal = () => {
    setOpenEditModal(false);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    let temp = [...data];

    if (sectionData != null) {
      temp[selectedSection] = sectionData;
      setData(temp);
    }
    setLoading(true);
    try {
      let updateReport = await axios.patch(
        `${config.api.base_url}/api/custom/report/${reportId}`,
        {
          update: temp,
        }
      );
      notification.success(
        updateReport?.data?.message || "Report updated successfully"
      );
      closeModal();
    } catch (error) {
      console.log("ERROR OCCURED IN handleSaveChanges", error);
      notification.error(
        error?.response?.data?.message ||
          "Something went wrong while updating report"
      );
    }
    setLoading(false);
  };

  const handleChangeSelectedSection = (e, index) => {
    console.log(e, index);
    if (e.target.id == "section-options") {
      return;
    }

    if (selectedSection == index) {
      return;
    }

    if (sectionData != null) {
      setData((prev) => {
        let temp = [...prev];
        temp[selectedSection] = sectionData;
        return temp;
      });
    }

    setSelectedSection(index);
    setSectionData(data[index]);
  };

  const handleDeleteSlide = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedSection(null);
    setSectionData(null);
    if (!isNaN(index)) {
      if (window.confirm("Are you sure you want to delete this section?")) {
        setData((prev) => {
          let temp = [...prev];
          temp.splice(index, 1);
          return temp;
        });
      }
    }
  };

  const handleRedirect = () => {
    window.open(
      `/custom-slides?custReport=true&hub_id=${hub_id}&repid=${reportId}`,
      "_blank"
    );
  };

  const handleEditToggle = (value, index) => {
    setData((prev) => {
      const temp = [...prev];
      temp[index]["edited"] = value;
      return temp;
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "50px 0",
        }}>
        <img src={loader} alt="loading" />
      </div>
    );
  }

  return (
    <Modal
      className="edit-custom-reports-modal"
      open={openEditModal}
      onOk={handleSaveChanges}
      onCancel={closeModal}
      style={{
        width: "100%",
      }}
      width={"100%"}
      footer={
        <>
          <Button type="default" onClick={handleRedirect}>
            Add/Edit Custom Section
          </Button>
          <Button type="primary" danger onClick={closeModal}>
            Discard
          </Button>
          <Button type="primary" onClick={handleSaveChanges}>
            Save
          </Button>
        </>
      }>
      <div
        style={{
          height: "600px",
          overflowY: "auto",
          marginTop: "30px 0",
          display: "flex",
          justifyContent: "stretch",
          alignItems: "flex-start",
          gap: "20px",
          width: "100%",
        }}>
        <div
          style={{
            width: "40%",
          }}>
          {data?.length
            ? data.map((obj, index) => {
                if (obj?.template === "keyStrategicThemes") {
                  return null;
                }

                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      listStyleType: "none",
                      padding: "10px",
                      border: "1px solid lightgray",
                      borderRadius: "4px",
                      margin: "10px 0 4px",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleChangeSelectedSection(e, index)}>
                    <p>{obj.title}</p>
                    <div
                      id="section-options"
                      style={{
                        display: "flex",
                        gap: "10px",
                        cursor: "auto",
                        padding: "4px 0px",
                      }}>
                      <Switch
                        checked={obj?.edited}
                        onChange={(value) => handleEditToggle(value, index)}
                        title="Is this section edited?"
                      />
                      <img
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                        title="Delete this section"
                        onClick={(e) => handleDeleteSlide(e, index)}
                        src={deleteIcon}
                        alt="delete-icon"
                      />
                    </div>
                  </div>
                );
              })
            : ""}
        </div>

        <div
          style={{
            width: "60%",
          }}>
          {selectedSection !== null ? (
            <EditableObject data={sectionData} onChange={setSectionData} />
          ) : (
            <p>Please select a section to edit the data.</p>
          )}
        </div>
      </div>
      {/* <div
        style={{
          height: "600px",
          overflow: "auto",
          padding: "10px",
          marginTop: "30px",
        }}>
        {data?.length
          ? data.map((obj, index) => {
              if (obj?.template === "keyStrategicThemes") {
                return null;
              }
              return (
                <details
                  key={index}
                  name="report-accordion"
                  id="report-accordion"
                  onClick={(e) => handleChangeSelectedSection(e, index)}>
                  <summary
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      listStyleType: "none",
                      padding: "10px",
                      border: "1px solid lightgray",
                      borderRadius: "4px",
                      margin: "10px 0 4px",
                      userSelect: "none",
                      cursor: "pointer",
                    }}>
                    <p>{obj.title}</p>
                    <div
                      id="section-options"
                      style={{
                        display: "flex",
                        gap: "10px",
                        cursor: "auto",
                        padding: "4px 0px",
                      }}>
                      <Switch
                        checked={obj?.edited}
                        onChange={(value) => handleEditToggle(value, index)}
                        title="Is this section edited?"
                      />
                      <img
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                        title="Delete this section"
                        onClick={(e) => handleDeleteSlide(e, index)}
                        src={deleteIcon}
                        alt="delete-icon"
                      />
                    </div>
                  </summary>

                  {selectedSection == index ? (
                    <EditableObject
                      data={sectionData}
                      onChange={setSectionData}
                    />
                  ) : null}
                </details>
              );
            })
          : "No Data found"}
      </div> */}
    </Modal>
  );
}

export default EditCustomReportModal;
