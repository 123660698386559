import React, { useState } from "react";
import { Row, Column } from "./style";

import Graph from "../../../Graphs/index";
import { Button, Select } from "antd";
import { defaultAnalysisCustomSlide } from "../../../constants";
import DataModal from "./DataModal";
import CustomAnalysis from "../commonFunctions/CustomAnalysis";
const { Option } = Select;

function CustomTempOne({ data, fromInterface, editMode, onSave, saveLoading }) {
  const { graphs = [], analysis, slideHeading, _id = null } = data;
  console.log(data);
  const [tempConfig, setTempConfig] = useState({
    slideHeading,
    graphs: {
      ...(Array.isArray(graphs) ? graphs[0] : graphs),
    },

    analysis: analysis ? analysis : defaultAnalysisCustomSlide,
  });
  const [showDataModal, setShowDataModal] = useState(false);

  const list = [
    { name: "pie", id: "pie" },
    { name: "barline", id: "barline" },
  ];

  const handleChangeGraph = (value) => {
    setTempConfig((prev) => ({
      ...prev,
      graphs: { ...prev.graphs, graphType: value },
    }));
  };

  const addData = (data) => {
    setTempConfig((prev) => ({
      ...prev,
      graphs: { ...prev.graphs, data: data },
    }));
  };

  return (
    <>
      <div style={{ gap: "5px", display: "flex" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <div
            style={{
              display: "flex",

              width: "100%",
              alignItems: "flex-end",
              gap: "10px",
            }}
            className="uploadsection"
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <p className="exportLabels">
                <span className="mandatory">*</span>Select a Graph Type
              </p>
              <Select
                placeholder="Select Graph"
                optionFilterProp="children"
                showSearch
                onChange={handleChangeGraph}
                value={tempConfig.graphs.graphType}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {list.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Button onClick={() => setShowDataModal(true)}>Add Data</Button>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: "10px",
            }}
          >
            <input
              placeholder="slide heading"
              style={{ width: "100%" }}
              value={tempConfig.slideHeading}
              onChange={(e) =>
                setTempConfig((p) => {
                  return { ...p, slideHeading: e.target.value };
                })
              }
            />
          </div>

          <Row>
            <Column>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "10px",
                }}
              >
                <input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Graph heading"
                  value={tempConfig.graphs.heading}
                  onChange={(e) =>
                    setTempConfig((p) => {
                      return {
                        ...p,
                        graphs: {
                          ...p.graphs,
                          heading: e.target.value,
                        },
                      };
                    })
                  }
                />
              </div>

              <Graph
                legendsPositionDynamic={true}
                rotateValues={0}
                chartHeadingGap={
                  tempConfig.graphs.heading?.length > 60 ? 10 : 0
                }
                yHeadingMargin={
                  tempConfig.graphs.heading?.length > 60 ? 30 : 25
                }
                graph_config={tempConfig.graphs.graph_config}
                xHeadingMargin={0}
                wrapHeadingWidth={-100}
                legendsYPosition={100}
                headers={tempConfig.graphs.heading}
                data={tempConfig.graphs.data}
                meta={tempConfig?.graphs?.meta ? tempConfig.graph.meta : {}}
                width={
                  !fromInterface || fromInterface === undefined ? "566" : "500"
                }
                height={500}
                totalData={tempConfig.graphs.data}
                graphType={tempConfig.graphs.graphType}
                dataType={tempConfig.graphs.dataType}
                fromInterface={fromInterface}
                slideNo
              />
            </Column>
            <Column>
              <input
                placeholder="Analysis heading"
                value={tempConfig.analysis.heading}
                onChange={(e) =>
                  setTempConfig((p) => {
                    return {
                      ...p,
                      analysis: {
                        ...p.analysis,
                        heading: e.target.value,
                      },
                    };
                  })
                }
              />
              <div>
                <p>Analysis:</p>
                <CustomAnalysis
                  trends={tempConfig.analysis.data}
                  setTempConfig={setTempConfig}
                  edit_mode={true}
                  section_id={"data.section_id"}
                />
              </div>
            </Column>
          </Row>
        </div>
        <div>
          {" "}
          <Button
            type="primary"
            loading={saveLoading}
            disabled={saveLoading}
            onClick={() => {
              onSave({
                id: editMode && _id ? _id : "new",
                finalData: {
                  ...tempConfig,
                  graphs: [tempConfig.graphs],
                  analysis: tempConfig.analysis,
                },
              });
            }}
          >
            Save
          </Button>
        </div>
      </div>
      {showDataModal && (
        <DataModal
          addData={addData}
          graphType={tempConfig.graphs.graphType || ""}
          showDataModal={showDataModal}
          setShowDataModal={setShowDataModal}
        />
      )}
    </>
  );
}

export default CustomTempOne;
