import React, { useEffect, useState } from "react";
import InterfaceHeader from "../InterfaceHeader";
import { CustomSlideComponentStyleWrapper } from "./CustomSlideComponent";
import { Button, Modal, notification, Select, Spin, Switch } from "antd";
import axios from "axios";
import config from "../../config/Api";
import { SearchOutlined } from "@ant-design/icons";
import CustomSlideTemplate from "./CustomSlideTemplate";
import Table from "./Table";
import AttachReport from "./modals/AttachReport";
import { useHistory } from "react-router-dom";

const openNotification = (msg, type) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Data Deleted successful.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to delete data.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.error(args);
  } else if (type) {
    args = {
      message: type,
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification[type](args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification.warning(args);
  }
};
function CustomSlideComponent() {
  const navigate = useHistory();
  const [infoTabVis, setInfoTabVis] = React.useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [hubData, setHubData] = React.useState([]);
  const [titlesList, setTitlesList] = useState({ filter: [], original: [] });
  const [searchQuery, setSearchQuery] = useState({
    search: "",
    hub_id: "",
    custReport: false,
    repid: "",
  });
  const [modal, setShowModal] = useState({
    delete: false,
    addReport: false,
    copy: false,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [onGoingApiCall, setOnGoingApiCall] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const getHubList = async () => {
    setLoading(true);
    if (hubData.length == 0) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
        });
    }
  };
  React.useEffect(() => {
    getHubList();
  }, []);

  useEffect(() => {
    if (hubData.length > 0) {
      const params = new URLSearchParams(window.location.search);

      const custReport = params.get("custReport");
      const hubId = params.get("hub_id");
      const repId = params.get("repid");
      if (hubId && repId && custReport) {
        setSearchQuery((p) => {
          return { ...p, hub_id: hubId, repId, custReport, repid: repId };
        });
      }
    }
  }, [hubData]);

  const handleHubChange = async (value) => {
    setSearchQuery((p) => {
      return { ...p, hub_id: value };
    });
  };
  const fetchList = () => {
    if (searchQuery.hub_id) {
      setTableLoading(true);
      axios
        .get(
          `${config.api.base_url}/api/custom/slide?hub_id=${searchQuery.hub_id}${searchQuery.custReport ? "&custReport=true" : ""}${searchQuery.repid ? `&repid=${searchQuery.repid}` : ""}`
        )
        .then((resp) => {
          setTableLoading(false);
          setTitlesList((p) => {
            return { filter: resp.data || [], original: resp.data || [] };
          });
        })
        .catch((e) => {
          setTableLoading(false);
          openNotification("error", "error");
        });
    }
  };
  useEffect(() => {
    fetchList();
  }, [searchQuery.hub_id, searchQuery.custReport]);

  const onClickAction = (action, info) => {
    setSelectedRow(info);

    if (action === "edit") {
      navigate.push(`/custom-slides?edit=true&id=${info._id}`);
      setOpenCreate(true);
    } else {
      setShowModal((p) => {
        return { ...p, [action]: true };
      });
    }
  };

  const confirmAction = async (action) => {
    setLoading(true);

    switch (action) {
      case "copy":
        setShowModal((p) => {
          return { ...p, copy: false };
        });
        setLoading(false);
        break;
      case "addReport":
        const finalData = {
          slideHeading: selectedRow.slideHeading,
          meta: selectedRow.meta,
          section_id: selectedRow.section_id,
          pageNo: selectedRow.pageNo,
          tocInfo: selectedRow.tocInfo,
          ...(searchQuery.custReport
            ? { custom_report_id: selectedRow.report_id }
            : { report_id: selectedRow.report_id }),
        };

        axios
          .patch(`${config.api.base_url}/api/custom/slide-addReport`, {
            id: selectedRow._id,
            data: { ...finalData },
            customFlag: searchQuery.custReport,
          })
          .then(async (res) => {
            await fetchList();
          })
          .catch((err) => {
            notification.error("something went wrong");
          })
          .finally(() => {
            setShowModal((p) => {
              return { ...p, addReport: false };
            });
            setSelectedRow({});
            setSelectedRow({});
            setLoading(false);
          });

        break;
      case "delete":
        axios
          .delete(
            `${config.api.base_url}/api/custom/slide?id=${selectedRow._id}`
          )
          .then((res) => {
            setLoading(false);
            setShowModal((p) => {
              return { ...p, delete: false };
            });
            let curr = titlesList;

            curr = curr.filter.filter((item) => item._id != selectedRow._id);
            setTitlesList((p) => {
              return { filter: curr, original: curr };
            });

            notification.success({
              message: "delete",
              description: "Data Deleted successful.",
            });
          })
          .catch((err) => {
            setLoading(false);
            setShowModal((p) => {
              return { ...p, delete: false };
            });
            console.log(err);
            notification.error({
              message: "error ",
              description: "error while  Deleting",
            });
          });

        break;
      case "edit":
        break;
      default:
        break;
    }
  };

  // Update text immediately and trigger debounced API call
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery((prev) => ({
      ...prev,
      search: value,
    }));
    setTitlesList((p) => {
      const regex = new RegExp(value, "i");
      return {
        ...p,
        filter: p.original.filter(
          (item) =>
            regex.test(item.slideHeading) ||
            (item.reportDetails?.title && regex.test(item.reportDetails.title))
        ),
      };
    });
  };

  return (
    <>
      {" "}
      <InterfaceHeader />
      <CustomSlideComponentStyleWrapper>
        <div className="wrapper">
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}>
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}>
                Custom Slides
              </h2>{" "}
              <div className="contentWrapper">
                <div className="sectionContainer">
                  {/* LEFT SECTION */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}>
                    <div
                      style={{ opacity: openCreate ? 0 : 1 }}
                      className="dropdownContainer">
                      <label htmlFor="selectHub">Select Hub :</label>
                      <Select
                        disabled={onGoingApiCall}
                        style={{
                          display: "block",
                          width: "200px",
                        }}
                        id="selectHub"
                        placeholder="select"
                        value={searchQuery.hub_id}
                        loading={loading}
                        maxLength=""
                        onChange={handleHubChange}
                        options={hubData.map((item, index) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        autoFocus={true}
                      />
                    </div>
                    <div
                      style={{
                        opacity: openCreate ? 0 : 1,
                        width: " 33%",
                        border: "1px solid #0000003b",
                        borderRadius: "6px",
                        padding: "3px",
                        display: "flex",
                        gap: "10px",
                      }}>
                      {" "}
                      <input
                        disabled={!searchQuery.hub_id}
                        placeholder={
                          searchQuery.hub_id
                            ? "Type slide title or report name"
                            : "please select a hub first"
                        }
                        value={searchQuery.search}
                        onChange={handleSearchChange}
                        style={{ width: "100%", border: "none" }}
                      />
                      <SearchOutlined style={{ cursor: "pointer" }} />
                    </div>
                    <div
                      style={{
                        opacity: openCreate ? 0 : 1,
                        display: "flex",
                        alignItems: "inherit",
                        justifyContent: "space-between",
                        gap: "10px",
                        width: "15%",
                      }}>
                      <h4 style={{ margin: "0px" }}>Custom Reports</h4>{" "}
                      <Switch
                        checked={searchQuery.custReport}
                        onChange={() => {
                          setSearchQuery((p) => {
                            if (p.custReport) {
                              return {
                                ...p,
                                custReport: !p.custReport,
                                repid: "",
                              };
                            } else {
                              return { ...p, custReport: !p.custReport };
                            }
                          });
                        }}
                        disabled={!searchQuery.hub_id}
                      />
                    </div>
                    <Button
                      type="primary"
                      style={{
                        display: "block",
                        margin: "10px 10px 10px 0px",
                      }}
                      onClick={() =>
                        setOpenCreate((p) => {
                          navigate.push("/custom-slides", { replace: true });
                          if (!p) {
                            setEdit(true);
                          }
                          return !p;
                        })
                      }>
                      {openCreate ? (
                        <div>Close</div>
                      ) : (
                        <div>Create New Custom Slide</div>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              {openCreate ? (
                <CustomSlideTemplate
                  setOpenCreate={setOpenCreate}
                  edit={edit}
                  setEdit={setEdit}
                  fetchList={fetchList}
                  selectedRow={selectedRow}
                />
              ) : tableLoading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                  }}>
                  <Spin size="large" />
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  {titlesList.original && titlesList.filter.length > 0 ? (
                    <>
                      <div
                        style={{
                          gap: "15px",
                          overflow: "scroll",
                          maxHeight: "900px",
                        }}
                        className="titlesWrapper">
                        <table
                          style={{
                            top: "0px",
                            position: "sticky",
                            zIndex: 1,
                          }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "5%" }}>copy </td>
                              <td style={{ width: "5%" }}>Edit </td>
                              <td style={{ width: "15%" }}>Slide Title </td>
                              <td style={{ width: "15%" }}>Template </td>
                              <td style={{ width: "15%" }}>Last Updated on </td>
                              <td style={{ width: "30%" }}>
                                {searchQuery.custReport && "Custom "} Report{" "}
                              </td>
                              <td style={{ width: "5%" }}>Page No. </td>
                              <td style={{ width: "10%" }}>Delete </td>
                            </tr>
                          </tbody>
                        </table>
                        <table>
                          <tbody>
                            {titlesList.filter?.map((item, i) => (
                              <div
                                className="hoverEffectWund"
                                style={{
                                  background: " #80808008",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px 20px",
                                  justifyContent: "space-between",
                                }}
                                key={i}>
                                <Table
                                  item={item}
                                  onClickAction={onClickAction}
                                />
                              </div>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    titlesList.original?.length === 0 && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "50px",
                        }}>
                        {searchQuery.hub_id && (
                          <h4>Custom Slide are present</h4>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}>
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    <h4>Create Custom Slides</h4>
                    <br />
                    <div>
                      <p>⏺ Select your hub.</p>
                      <p>
                        ⏺ Static Slide upload Dimension will be W 1080 * H 620.
                      </p>
                      <p>
                        ⏺ Click on Count Results and verify the count with the
                        number of reports data you want to delete.
                      </p>
                      <p>
                        ⏺ Click Delete and then confirm to delete the data.
                      </p>
                      <p>
                        ⏺ A copy of deleted data will be downloaded on your
                        device.
                      </p>
                      <p>
                        ⏺ Deleted data can be uploaded back using Upload Data
                        button.
                      </p>
                      <br />
                      <h4>Steps to Reupload Deleted Segmentation Data.</h4>
                      <br />
                      <div>
                        <p>
                          ⏺ To upload the deleted data you can either upload
                          the ME data sheet from the Upload Sheet section or you
                          can Select Hub for which you want to upload the data.
                        </p>
                        <p>
                          ⏺ Select the JSON file and then click on Upload
                          button.
                        </p>
                      </div>
                      <hr></hr>
                      <h4>
                        <center>
                          Please contact Tech in case of any query.
                        </center>
                      </h4>
                    </div>
                  </b>
                </div>
              )}
            </div>
          </div>
        </div>
      </CustomSlideComponentStyleWrapper>
      <Modal
        className="UploadModal"
        width={800}
        visible={modal.addReport}
        onCancel={() =>
          setShowModal((p) => {
            return { ...p, addReport: false };
          })
        }
        footer={[
          <Button
            type="primary"
            style={{
              backgroundColor: "gray",
              borderColor: "gray",
            }}
            onClick={() =>
              setShowModal((p) => {
                return { ...p, addReport: false };
              })
            }
            disabled={onGoingApiCall}>
            Cancel
          </Button>,
          <Button
            loading={loading}
            type="primary"
            onClick={() => confirmAction("addReport")}
            disabled={
              onGoingApiCall || !selectedRow.pageNo || !selectedRow.slideHeading
            }>
            Save
          </Button>,
        ]}>
        {modal.addReport && (
          <AttachReport
            modalData={selectedRow}
            setModalData={setSelectedRow}
            customFlag={searchQuery.custReport}
          />
        )}
      </Modal>
      <Modal
        className="UploadModal"
        width={500}
        visible={modal.copy}
        onCancel={() =>
          setShowModal((p) => {
            return { ...p, copy: false };
          })
        }
        footer={[
          <Button
            type="primary"
            style={{
              backgroundColor: "gray",
              borderColor: "gray",
            }}
            onClick={() =>
              setShowModal((p) => {
                return { ...p, copy: false };
              })
            }
            disabled={onGoingApiCall}>
            Cancel
          </Button>,
          <Button
            loading={loading}
            type="primary"
            onClick={() => confirmAction("copy")}
            disabled={onGoingApiCall}>
            Copy
          </Button>,
        ]}>
        copy
      </Modal>
      <Modal
        className="UploadModal"
        width={500}
        visible={modal.delete}
        onCancel={() =>
          setShowModal((p) => {
            return { ...p, delete: false };
          })
        }
        footer={[
          <Button
            type="primary"
            style={{
              backgroundColor: "gray",
              borderColor: "gray",
            }}
            onClick={() =>
              setShowModal((p) => {
                return { ...p, delete: false };
              })
            }
            disabled={onGoingApiCall}>
            Cancel
          </Button>,
          <Button
            loading={loading}
            type="primary"
            onClick={() => confirmAction("delete")}
            disabled={onGoingApiCall}>
            Delete
          </Button>,
        ]}>
        are you sure you want to delete this slide
      </Modal>
    </>
  );
}

export default CustomSlideComponent;
