import React from "react";

import { Container, Row } from "../style";

function FinalCusTemFour(props) {
  const { data } = props;
  return (
    <Container formInterface={true} style={{ height: "620px" }}>
      <Row style={{ justifyContent: "center" }}>
        <img src={data?.url} alt="Static Slide" />
      </Row>
    </Container>
  );
}

export default FinalCusTemFour;
