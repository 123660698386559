import { Button } from "antd";
import React from "react";

function ShowToc({ data, pad, depth, tocFunction, customSlide, depthTree }) {
  return (
    <div>
      {data.map((item, i) => {
        return (
          <div key={i + item.title}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Button
                onClick={() =>
                  tocFunction(
                    "addBelow",
                    depth,
                    i,
                    `${depthTree}+++${item.title}`,
                    item.title
                  )
                }
              >
                + show below
              </Button>
              <Button
                onClick={() =>
                  tocFunction(
                    "addChild",
                    depth,
                    i,
                    `${depthTree}+++${item.title}`,
                    item.title
                  )
                }
              >
                + show as child
              </Button>
              <div>{item.title}</div>
            </div>{" "}
            {customSlide?.tocInfo &&
              customSlide.tocInfo.depthTree ===
                `${depthTree}+++${item.title}` && (
                <div
                  style={{
                    margin: `5px ${customSlide.tocInfo.isChild ? 5 + pad : 0}px`,
                    padding: "5px",
                    borderRadius: "5px",
                    background: " #00000008",
                    boxShadow: "2px 1px 0px 0px #00000036",
                    fontWeight: "bold",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    customSlide TOC will come under {item.title}{" "}
                    {customSlide.tocInfo.isChild ? "as child" : ""}
                  </div>
                  <Button onClick={() => tocFunction("remove")}>Remove</Button>
                </div>
              )}
            <div
              style={{
                margin: `5px ${5 + pad}px`,
              }}
            >
              {item.children && item.children.length > 0 && (
                <ShowToc
                  depthTree={`${depthTree}+++${item.title}`}
                  customSlide={customSlide}
                  tocFunction={tocFunction}
                  data={item.children}
                  pad={pad + 5}
                  depth={depth + 1}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ShowToc;
