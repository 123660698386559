import React from "react";
import Subheading from "../../../Subheading";
import { Container, Row, Column, FullColumn } from "../style";

function FinalCusTemSeven() {
  return (
    <Container formInterface={true} style={{ height: "620px" }}>
      T7
    </Container>
  );
}

export default FinalCusTemSeven;
