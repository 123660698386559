import List from "@editorjs/list";
import SimpleImage from "./components/CustomBlock/simple-image/simple-image";
import Subscript from "./components/CustomBlock/subscript/subscript";
import Superscript from "./components/CustomBlock/superscript/Superscript";
import Switchtextuppercase from "./components/CustomBlock/switch-text-uppercase/switch-text-uppercase";
import Switchtextlowercase from "./components/CustomBlock/switch-text-lowercase/switch-text-lowercase";
import Switchtexttitlecase from "./components/CustomBlock/switch-text-titlecase/switch-text-titlecase";
// import Bold from '@editorjs/bold';
export const EDITOR_JS_TOOLS = {
  list: {
    class: List,

    inlineToolbar: true,
    toolbox: {
      title: "Insert List",
    },

    config: {
      defaultStyle: "unordered",
    },
    shortcut: "CTRL+SHIFT+L",
  },
  // subscript:Subscript,

  subscript: {
    class: Subscript,
    inlineToolbar: true,
    toolbox: {
      title: "Insert Subscript",
    },
    shortcut: "CTRL+DOWN",
  },
  superscript: {
    class: Superscript,
    inlineToolbar: true,
    toolbox: {
      title: "Insert Superscript",
    },
    shortcut: "CTRL+UP",
  },
  switchtextuppercase: {
    class: Switchtextuppercase,
    toolbar: {
      title: "Switch Text Uppercase",
    },
    inlineToolbar: true,
    shortcut: "CTRL+SHIFT+U",
  },
  switchtextlowercase: {
    class: Switchtextlowercase,
    toolbar: {
      title: "Switch Text Lowercase",
    },
    inlineToolbar: true,
    shortcut: "CTRL+SHIFT+L",
  },
  switchtexttitlecase: {
    class: Switchtexttitlecase,
    toolbar: {
      title: "Switch Text Titlecase",
    },
    inlineToolbar: true,
    shortcut: "CTRL+SHIFT+I",
  },

  image: {
    class: SimpleImage,
    inlineToolbar: true,
    toolbox: {
      title: "Insert Image",
    },
    shortcut: "CTRL+I",
  },
  //  {
  //   class: Subscript,
  //   inlineToolbar: true,
  //   shortcut: 'CTRL+S',
  //   toolbar: {
  //     title: 'subscript',
  //   },
  // config: {
  //   defaultStyle: 'subscript',
  // },
  // }
};

export const defaultAnalysisCustomSlide = {
  heading: "heading Loriem",
  data: {
    analysis: [
      {
        text: "testing first",
        hyperLink: "",
        counter: 1,
      },
      {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor sapien ut felis faucibus, ac pulvinar orci fermentum.",
        hyperLink: "",
        counter: 1,
      },
    ],
    referenced_analysis: [
      {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor sapien ut felis faucibus, ac pulvinar orci fermentum.",
        hyperLink: "",
        counter: 1,
      },
      {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor sapien ut felis faucibus, ac pulvinar orci fermentum.",
        hyperLink: "",
        counter: 1,
      },
    ],
  },
};
