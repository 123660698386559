import React from "react";
import Heading from "../../../Heading";
import Graph from "../../../../Graphs/Graphs";
import Subheading from "../../../Subheading";
import { Container, Row, Column, FullColumn } from "../style";
import Analysis from "../../../Analysis";
function FinalCusTemSix(props) {
  const { data } = props;
  return (
    <Container formInterface={true} style={{ height: "620px" }}>
      <Heading heading={data.slideHeading} />
      <Row>
        {[0, 1].map((graphNo) => {
          return (
            <Column>
              <Graph
                chartHeadingGap={10}
                wrapHeadingWidth={400}
                yHeadingMargin={25}
                headers={data.graphs[graphNo].heading}
                data={data.graphs[graphNo].data}
                graph_config={data.graphs[graphNo].graph_config}
                width={540}
                totalData={data.graphs[graphNo].data}
                graphType={data.graphs[graphNo].graphType}
                dataType={data.graphs[graphNo].dataType}
                yAxisPercentage={data.graphs[graphNo].YAxisPercentage}
                dynamiclegends={
                  data.graphs[graphNo].legends
                    ? data.graphs[graphNo].legends
                    : ["Value", "YOY"]
                }
                min_SYAxis={
                  data.graphs[graphNo].min_SYAxis
                    ? data.graphs[graphNo].min_SYAxis
                    : false
                }
                graph_meta={
                  data.graphs[graphNo].graph_meta
                    ? data.graphs[graphNo].graph_meta
                    : {}
                }
                height={266}
                slideNo
              />
            </Column>
          );
        })}
      </Row>

      <FullColumn>
        <Subheading subheading={data.analysis.heading} />
        <Analysis
          trends={data.analysis.data}
          edit_mode={false}
          section_id={
            data.section_id
              ? data.section_id
              : data.meta.section_id
                ? data.meta.section_id
                : data.analysis.heading
          }
        />
      </FullColumn>
    </Container>
  );
}

export default FinalCusTemSix;
