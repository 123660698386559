import React, { useState } from "react";
import { Row } from "./style";
import { Button, Modal, notification, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config/Api";

function getToken() {
  return window.localStorage.getItem("token");
}

function CustomTempFour(props) {
  const { data, editMode, onSave, saveLoading } = props;
  const {
    url = "https://netsolutions.com/insights/wp-content/uploads/2022/07/top-25-software-testing-types.webp",
    slideHeading,
    _id = null,
  } = data;

  const [showDataModal, setShowDataModal] = useState(false);
  const [onGoingApiCall, setOnGoingApiCall] = useState(false);
  const [showUploadPrompt, setShowUploadPrompt] = useState(false);

  const [tempConfig, setTempConfig] = useState({
    slideHeading,
    url,
  });
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image upload
  const handleUpload = async () => {
    if (!selectedImage) {
      notification.warning({
        message: "Please select an SVG image to upload.",
      });
      return;
    }

    try {
      setOnGoingApiCall(true);
      const formData = new FormData();
      formData.append("image", selectedImage);

      const response = await axios.post(
        `${config.api.base_url}/api/additional_routes/upload_files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      const uploadedUrl = Object.values(response.data.urls)[0];
      console.log(uploadedUrl);
      setTempConfig((prev) => ({ ...prev, url: uploadedUrl }));
      notification.success({ message: "Image uploaded successfully!" });
    } catch (error) {
      console.error("Upload Error:", error);
      notification.error({ message: "Failed to upload image." });
    } finally {
      setOnGoingApiCall(false);
    }
  };

  // Restrict to SVG files only
  const beforeUpload = (file) => {
    const isSvg = file.type === "image/svg+xml";
    if (!isSvg) {
      notification.error({ message: "You can only upload SVG files!" });
    } else {
      setSelectedImage(file);
      setShowUploadPrompt(true); // Show the message
    }
    return false; // Prevent automatic upload
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <input
            placeholder="Slide heading"
            style={{ width: "100%" }}
            value={tempConfig.slideHeading}
            onChange={(e) =>
              setTempConfig((p) => ({ ...p, slideHeading: e.target.value }))
            }
          />
        </div>

        <Row style={{ display: "flex", flexDirection: "column" }}>
          {tempConfig.url ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button onClick={() => setTempConfig((p) => ({ ...p, url: "" }))}>
                Delete
              </Button>
              <img
                style={{ borderTop: "1px solid" }}
                height="510px"
                alt={tempConfig.slideHeading || "img"}
                src={tempConfig.url}
              />
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <h4>Upload An SVG Image</h4>
              <Upload
                beforeUpload={beforeUpload}
                showUploadList={false}
                accept=".svg"
                disabled={onGoingApiCall}
              >
                <Button icon={<UploadOutlined />}>Select SVG File</Button>
              </Upload>
              <Button onClick={handleUpload} loading={onGoingApiCall}>
                Upload
              </Button>
              {showUploadPrompt && (
                <div style={{ marginTop: "10px", color: "red" }}>
                  Please click on the Upload button to proceed.
                </div>
              )}
            </div>
          )}
        </Row>
      </div>
      <Button
        type="primary"
        loading={saveLoading}
        disabled={saveLoading}
        onClick={() => {
          onSave({
            id: editMode && _id ? _id : "new",
            finalData: { ...tempConfig },
          });
        }}
      >
        Save
      </Button>
      {showDataModal && <Modal>hello</Modal>}
    </div>
  );
}

export default CustomTempFour;
