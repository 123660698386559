import { Modal } from "antd";
import React from "react";

function ConfirmationModal({ open, title, onCancel, onConfirmation }) {
  return (
    <Modal
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={onConfirmation}
    />
  );
}

export default ConfirmationModal;
