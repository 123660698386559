import React, { useState } from "react";
import { Row } from "./style";

import Graph from "../../../Graphs/Graphs";

import { Button, Select } from "antd";
import DataModal from "./DataModal";
import { defaultAnalysisCustomSlide } from "../../../constants";
import CustomAnalysis from "../commonFunctions/CustomAnalysis";
const { Option } = Select;

const list = [
  { name: "pie", id: "pie" },
  { name: "barline", id: "barline" },
];

function CustomTempTwo(props) {
  console.log(props);
  const { data, editMode, onSave, saveLoading } = props;
  const { graphs = [], analysis, slideHeading, _id = null } = data;
  const [showDataModal, setShowDataModal] = useState(false);

  const [tempConfig, setTempConfig] = useState({
    slideHeading,
    graphs: {
      ...(Array.isArray(graphs) ? graphs[0] : graphs),
    },

    analysis: analysis ? analysis : defaultAnalysisCustomSlide,
  });
  const handleChangeGraph = (value) => {
    setTempConfig((prev) => ({
      ...prev,
      graphs: { ...prev.graphs, graphType: value },
    }));
  };
  const addData = (data) => {
    setTempConfig((prev) => ({
      ...prev,
      graphs: { ...prev.graphs, data: data },
    }));
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div
          style={{
            display: "flex",

            width: "100%",
            alignItems: "flex-end",
            gap: "10px",
          }}
          className="uploadsection"
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <p className="exportLabels">
              <span className="mandatory">*</span>Select a Graph Type
            </p>
            <Select
              placeholder="Select Graph"
              optionFilterProp="children"
              showSearch
              onChange={handleChangeGraph}
              value={tempConfig.graphs.graphType}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {list.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Button onClick={() => setShowDataModal(true)}>Add Data</Button>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "10px",
          }}
        >
          <input
            placeholder="slide heading"
            style={{ width: "100%" }}
            value={tempConfig.slideHeading}
            onChange={(e) =>
              setTempConfig((p) => {
                return { ...p, slideHeading: e.target.value };
              })
            }
          />
        </div>

        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: "10px",
            }}
          >
            <input
              style={{
                width: "100%",
              }}
              placeholder="Graph heading"
              value={tempConfig.graphs.heading}
              onChange={(e) =>
                setTempConfig((p) => {
                  return {
                    ...p,
                    graphs: {
                      ...p.graphs,
                      heading: e.target.value,
                    },
                  };
                })
              }
            />
          </div>
          <Graph
            chartHeadingGap={10}
            wrapHeadingWidth={400}
            yHeadingMargin={25}
            headers={tempConfig.graphs.heading}
            data={tempConfig.graphs.data}
            graph_config={tempConfig.graphs.graph_config}
            width={1080}
            totalData={tempConfig.graphs.data}
            graphType={tempConfig.graphs.graphType}
            dataType={tempConfig.graphs.dataType}
            yAxisPercentage={tempConfig.graphs.YAxisPercentage}
            dynamiclegends={
              tempConfig.graphs.legends
                ? tempConfig.graphs.legends
                : ["Value", "YOY"]
            }
            min_SYAxis={
              tempConfig.graphs.min_SYAxis
                ? tempConfig.graphs.min_SYAxis
                : false
            }
            graph_meta={
              tempConfig.graphs.graph_meta ? tempConfig.graphs.graph_meta : {}
            }
            height={266}
            slideNo
          />
        </Row>

        <Row style={{ display: "flex", flexDirection: "column" }}>
          <input
            placeholder="Analysis heading"
            value={tempConfig.analysis.heading}
            onChange={(e) =>
              setTempConfig((p) => {
                return {
                  ...p,
                  analysis: {
                    ...p.analysis,
                    heading: e.target.value,
                  },
                };
              })
            }
          />

          <CustomAnalysis
            trends={tempConfig.analysis.data}
            setTempConfig={setTempConfig}
            edit_mode={true}
            section_id={"data.section_id"}
          />
        </Row>
      </div>
      <div>
        {" "}
        <Button
          type="primary"
          loading={saveLoading}
          disabled={saveLoading}
          onClick={() => {
            onSave({
              id: editMode && _id ? _id : "new",
              finalData: {
                ...tempConfig,
                graphs: [tempConfig.graphs],
                analysis: tempConfig.analysis,
              },
            });
          }}
        >
          Save
        </Button>
      </div>
      {showDataModal && (
        <DataModal
          addData={addData}
          graphType={tempConfig.graphs.graphType || ""}
          showDataModal={showDataModal}
          setShowDataModal={setShowDataModal}
        />
      )}
    </div>
  );
}

export default CustomTempTwo;
