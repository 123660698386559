import React from "react";
import Heading from "../../../Heading";
import Graph from "../../../../Graphs/Graphs";
import Subheading from "../../../Subheading";
import { Button, Spin } from "antd";
import { Container, Row, Column } from "../style";
import Analysis from "../../../Analysis";
function FinalCusTemOne(props) {
  const { data } = props;
  return (
    <Container formInterface={true} style={{ height: "620px" }}>
      <Heading heading={data.slideHeading} />
      <Row>
        <Column>
          <Graph
            legendsPositionDynamic={true}
            rotateValues={0}
            chartHeadingGap={data.graphs[0].heading?.length > 60 ? 10 : 0}
            yHeadingMargin={data.graphs[0].heading?.length > 60 ? 30 : 25}
            graph_config={data.graphs[0].graph_config}
            xHeadingMargin={0}
            wrapHeadingWidth={-100}
            legendsYPosition={100}
            headers={data.graphs[0].heading}
            data={data.graphs[0].data}
            meta={data?.graphs[0]?.meta ? data.graph[0].meta : {}}
            width={500}
            height={516}
            totalData={data.graphs[0].data}
            graphType={data.graphs[0].graphType}
            dataType={data.graphs[0].dataType}
            fromInterface={true}
            slideNo={data.PageNo}
          />
        </Column>
        <Column>
          <div>
            <Subheading subheading={data.analysis.heading} />
            <Analysis
              trends={data.analysis.data}
              edit_mode={false}
              section_id={
                data.section_id
                  ? data.section_id
                  : data.meta.section_id
                    ? data.meta.section_id
                    : data.analysis.heading
              }
            />
          </div>
        </Column>
      </Row>
    </Container>
  );
}

export default FinalCusTemOne;
