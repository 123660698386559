import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Bar from "../Graphs/Bar";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import img3 from "../images/img3.png";
import img4 from "../images/img4.png";
import solid from "../images/Solid.svg";
import { Table } from "antd";
import { Card } from "antd";
import { FeedProdctionContainer } from "../styles/FeedProductive.style";
import Heading from "../components/Heading";

var Base64 = require("js-base64").Base64;

const { Meta } = Card;

export const feedProductionDemoData = {
  id: "3.2 FEED PRODUCTION TRENDS",
  slideName: "feedproduction",
  title: "3 PARENT MARKET TRENDS",
  subtitle: "3.2 FEED PRODUCTION TRENDS",
  slideReference: "2. KEY INDUSTRY TRENDS",
  slug: "North America Probiotics Market",
  data: [
    {
      type: "Swine",
      description: [
        {
          text: "Swine - Global growth of feed production by 3%, driven by.",
        },
        {
          text: "Shift in consumer preferences led to a flat growth over the past year.",
        },
        {
          text: "Shift in consumer preferences led to a flat growth over the past year.",
        },
      ],
    },
    {
      type: "Poultry",
      description: [
        {
          text: "Swine - Global growth of feed production by 3%, driven by.",
        },
        {
          text: "Shift in consumer preferences led to a flat growth over the past year.",
        },
        {
          text: "Shift in consumer preferences led to a flat growth over the past year.Shift in consumer preferences led to a flat growth over the past year. Shift in consumer preferences led to a flat growth over the past year.",
        },
      ],
    },
    {
      type: "Ruminant",
      description: [
        {
          text: "Ruminants - Global growth of feed production by 3%, driven by highest.",
        },
      ],
    },
  ],
  graphs: [
    {
      graphType: "bar",
      data: [
        {
          label: "2011",
          value: 235.17,
        },
        {
          label: "2012",
          value: 257.634,
        },
        {
          label: "2013",
          value: 259.3134,
        },
        {
          label: "2014",
          value: 264.6324,
        },
        {
          label: "2015",
          value: 268.8039,
        },
        {
          label: "2016",
          value: 278.694,
        },
        {
          label: "2017",
          value: 288.819,
        },
        {
          label: "2018",
          value: 302.805,
        },
        {
          label: "2019",
          value: 311.715,
        },
      ],
      heading:
        "Global Compound Feed Production in million metric ton, 2011-2029​",
    },
    {
      graphType: "pie",
      data: [
        {
          label: "Poultry",
          value: 484777.89,
          cagr: "1.29",
          color: "#FFEDC7",
          icon: img2,
        },
        {
          label: "Ruminant",
          value: 219.355,
          cagr: "1.52",
          color: "#E0F9FF",
          icon: img1,
        },
        {
          label: "Swine",
          value: 311.715,
          cagr: "1.52",
          color: "#D7F6EB",
          icon: img3,
        },
        {
          label: "Aquaculture",
          value: 46.18,
          cagr: "1.52",
          color: "#E6E3FC",
          icon: img4,
        },
      ],
      heading: "Feed Production Share, by Animal Type, Global, 2019",
    },
  ],
};

class FeedProductive1 extends React.Component {
  state = {
    data: this.props.data || feedProductionDemoData,
    tableData: [],
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    const { id, title, subtitle, slug, data, graphs } = this.state.data;
    const { slideNo } = this.props;
    let color = {
      poultry: "#FFEDC7",
      ruminant: "#E0F9FF",
      swine: "#D7F6EB",
      aquaculture: "#E6E3FC",
    };
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    const columns = [
      {
        title: "Animal Type",
        align: "center",
        dataIndex: "type",
        render: (text) => <div className="rowHeading">{text}</div>,
      },
      {
        title: "Key Trends",
        align: "left",
        dataIndex: "description",
        render: (text) => (
          <ul>
            {text.map((item) => (
              <li className="feedproductionList">{item.text}</li>
            ))}
          </ul>
        ),
      },
    ];
    return (
      <FeedProdctionContainer data={this.state.data} id={pageId}>
        <div className="page">
          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Header slideTitle={this.state.data.slideName} />
          ) : null}
          <div
            className="container"
            style={{ height: this.props.fromInterface ? "auto" : "626px" }}
          >
            {/* <h2 className="heading">{this.state.data.subtitle}</h2> */}
            <Heading heading={this.state.data.subtitle} />
            <div className="main-container">
              <div className="left-container">
                <Table
                  bordered={false}
                  pagination={false}
                  columns={columns}
                  dataSource={data}
                />
              </div>
              <div className="right-container">
                <h3 className="subheading">
                  {graphs ? graphs[0].heading : ""}
                </h3>
                {graphs ? (
                  <Bar
                    width={585}
                    height={200}
                    axes={false}
                    data={{ data: graphs[0].data }}
                    labels={true}
                    id={`id${slideNo}1`}
                    footers={this.state.graphFooterText}
                    headers={graphs[0].heading}
                  />
                ) : (
                  ""
                )}
                <h3 className="subheading cardheading">
                  {graphs ? graphs[1].heading : ""}
                </h3>
                <div className="main-container">
                  {graphs
                    ? graphs[1].data.map((item, id) => (
                        <div
                          className="card"
                          style={{
                            width: 135,
                            height: 204,
                            margin: "5px",
                            backgroundColor: color[
                              (item.label + "").toLowerCase()
                            ]
                              ? color[(item.label + "").toLowerCase()]
                              : "#E0F9FF",
                          }}
                        >
                          <div className="img">
                            {" "}
                            <img
                              src={item.icon}
                              alt="img"
                              style={{ width: 100, height: 70 }}
                            />
                          </div>
                          <div className="cagr">
                            <img
                              src={solid}
                              alt=""
                              style={{ marginRight: "6px" }}
                            />
                            <span>{item.cagr}%</span>
                          </div>
                          <p
                            className="card-details card-number"
                            style={{ fontSize: 20 }}
                          >
                            {item.value}
                          </p>
                          <p className="card-details card-name">{item.label}</p>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slideNo={slideNo}
              slug={slug}
              reseller={this.state.reseller}
            />
          ) : null}
        </div>
      </FeedProdctionContainer>
    );
  }
}

export default FeedProductive1;
