import React from "react";
import Footer from "../../Footer";
import { Page } from "./style";
import Header from "../../Header";
import FinalCusTemOne from "./Templates/FinalCusTemOne";
import FinalCusTemTwo from "./Templates/FinalCusTemTwo";
import FinalCusTemThee from "./Templates/FinalCusTemThree";
import FinalCusTemFour from "./Templates/FinalCusTemFour";
import FinalCusTemFive from "./Templates/FinalCusTemFive.js";
import FinalCusTemSix from "./Templates/FinalCusTemSix.js";
import FinalCusTemSeven from "./Templates/FinalCusTemSeven.js";
var Base64 = require("js-base64").Base64;
function FinalCustomSlide(props) {
  const { data, index, counter } = props;
  let slideNoElementId = undefined;
  if (!data?.section_id) {
    data.section_id = "empty";
  }
  const pageId = data.section_id
    ? Base64.encode(data.section_id)
    : data.meta.section_id
      ? Base64.encode(data.meta.section_id)
      : undefined;

  let { template_name } = data;
  if (pageId) {
    slideNoElementId = "slideNO" + pageId;
  }

  const renderTemplate = (template) => {
    switch (template) {
      case "Temp1":
        return <FinalCusTemOne data={data} />;
      case "Temp2":
        return <FinalCusTemTwo data={data} />;
      case "Temp3":
        return <FinalCusTemThee data={data} />;
      case "Temp4":
        return <FinalCusTemFour data={data} />;
      case "Temp5":
        return <FinalCusTemFive data={data} />;
      case "Temp6":
        return <FinalCusTemSix data={data} />;
      case "Temp7":
        return <FinalCusTemSeven data={data} />;
      default:
        return <></>;
    }
  };
  return (
    <Page className="page" id={pageId}>
      <Header slideTitle={"data.type"} />

      {renderTemplate(template_name)}
      <Footer
        slug={data.slug}
        slideNo={data.pageNo}
        slideNoElementId={slideNoElementId}
        fromInterface={true}
      />
    </Page>
  );
}

export default FinalCustomSlide;
