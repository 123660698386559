import {
  CopyOutlined,
  DeleteOutlined,
  DeleteRowOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import moment from "moment";
import React, { useState } from "react";

function Table({ item, onClickAction }) {
  const [showEdit, setShowEdit] = useState(false);
  return (
    <tr style={{ width: "100%", display: "flex" }}>
      <td style={{ width: "5%", marginTop: "0px" }}>
        <CopyOutlined onClick={() => onClickAction("copy", item)} />
      </td>
      <td style={{ width: "5%", marginTop: "0px" }}>
        <EditOutlined onClick={() => onClickAction("edit", item)} />
      </td>
      <td
        style={{
          marginTop: "0px",
          width: "15%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        {item.slideHeading}
      </td>

      <td style={{ width: "15%", marginTop: "0px" }}>{item.template_name}</td>
      <td style={{ width: "15%", marginTop: "0px" }}>
        {moment(item.updatedAt).format("MMMM Do YYYY") || "N/A"}
      </td>
      <td
        style={{
          width: "30%",
          marginTop: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        {item.reportDetails ? (
          <div
            style={{ width: "100%" }}
            onMouseEnter={() => {
              setShowEdit((p) => !p);
            }}
            onMouseLeave={() => {
              setShowEdit((p) => !p);
            }}
            onClick={() => {
              onClickAction("addReport", item);
            }}
          >
            {showEdit ? (
              <div style={{ width: "100%" }}>edit</div>
            ) : (
              item.reportDetails.title
            )}
          </div>
        ) : item.custom_report_id ? (
          <div
            style={{ width: "100%" }}
            onMouseEnter={() => {
              setShowEdit((p) => !p);
            }}
            onMouseLeave={() => {
              setShowEdit((p) => !p);
            }}
            onClick={() => {
              onClickAction("addReport", item);
            }}
          >
            {showEdit ? (
              <div style={{ width: "100%" }}>edit</div>
            ) : (
              item.custom_report_id.variant_name
            )}
          </div>
        ) : (
          <div
            onClick={() => {
              onClickAction("addReport", item);
            }}
          >
            Not yet attached, Please Attach {<PlusCircleOutlined />}
          </div>
        )}
      </td>
      <td style={{ width: "5%", marginTop: "0px" }}>{item.pageNo}</td>
      <td style={{ width: "10%", marginTop: "0px" }}>
        <Button onClick={() => onClickAction("delete", item)}>
          Delete <DeleteOutlined />
        </Button>
      </td>
    </tr>
  );
}

export default Table;
