import axios from "axios";
import { auth } from "../firebase/Firebase";
import configs from "./Config";
let idToken;
let customClaims = null;
const config = {
  api: {
    base_url:
      window.location.href.indexOf("mordorintelligence") !== -1
        ? process.env.REACT_APP_LIVE_BACKEND_PORT
        : window.location.href.indexOf("mordorlabs.com") !== -1
          ? process.env.REACT_APP_TEST_BACKEND_PORT
          : process.env.REACT_APP_BACKEND_PORT, // Change The Port Here According To The Requirement.
    website_url:
      window.location.href.indexOf("mordorintelligence") !== -1
        ? "https://www.mordorintelligence.com/"
        : window.location.href.indexOf("mordorlabs.com") !== -1
          ? "https://mordorlabs.com/"
          : "https://mordorlabs.com/",
    puppeteer_ms_url:
      window.location.href.indexOf("mordorintelligence") !== -1
        ? process.env.REACT_APP_LIVE_PUPPETEER_BACKEND_PORT
        : window.location.href.indexOf("mordorlabs.com") !== -1
          ? process.env.REACT_APP_TEST_PUPPETEER_BACKEND_PORT
          : process.env.REACT_APP_PUPPETEER_BACKEND_PORT,
    OAuth2State:
      window.location.href.indexOf("mordorintelligence") !== -1
        ? process.env.REACT_APP_LIVE_OAUTH2STATE
        : window.location.href.indexOf("mordorlabs.com") !== -1
          ? process.env.REACT_APP_TEST_OAUTH2STATE
          : process.env.REACT_APP_TEST_OAUTH2STATE,
    pptgen_url:
      window.location.href.indexOf("mordorintelligence") !== -1
        ? "https://pptgen.mordorintelligence.com/"
        : window.location.href.indexOf("mordorlabs.com") !== -1
          ? "https://pptgen.mordorlabs.com/"
          : "http://localhost:3456/",
    express_url:
      window.location.href.indexOf("mordorintelligence") !== -1
        ? "https://www.mordorintelligence.com/preview"
        : window.location.href.indexOf("mordorlabs.com") !== -1
          ? "https://express.mordorlabs.com/preview"
          : "https://express.mordorlabs.com/preview",
  },
};

function getToken() {
  return window.localStorage.getItem(configs.LOCAL_STORAGE_TOKEN.Name);
}

async function AttachAuthHeader() {
  // if (window.location.pathname.toLowerCase().includes("final-report")) {
  //   let testTokennn = await auth.signInWithEmailAndPassword(
  //     "test@mordorintelligence.com",

  //     process.env.REACT_APP_STAGE === "PRODUCTION" ? `tech.MI#47` : `password`
  //   );

  //   await auth.updateCurrentUser(testTokennn.user);

  // }

  if (
    !window.location.pathname.toLowerCase().includes("final-report") &&
    !window.location.pathname.toLowerCase().includes("custom-report-preview") &&
    !window.location.pathname.toLowerCase().includes("rd-graph")
  )
    axios.interceptors.request.use(async function (config) {
      try {
        if (
          config.url.includes("lorien") ||
          config.url.includes("localhost") ||
          config.url.includes("puppeteer-ms") ||
          config.url.includes("pptgen")
        ) {
          let token = getToken();

          var signInTime = new Date(
            window.localStorage.getItem(configs.LOCAL_STORAGE_TOKEN.meta)
          );
          var currentTime = new Date();
          const milliseconds = Math.abs(currentTime - signInTime);
          const hours = milliseconds / 36e5;

          if (
            (hours > 1 && auth.currentUser) ||
            token === "undefined" ||
            token === "[object Object]"
          ) {
            idToken = await auth.currentUser.getIdTokenResult(true);

            window.localStorage.setItem(
              configs.LOCAL_STORAGE_TOKEN.Name,
              idToken.token
            );
            window.localStorage.setItem(
              configs.LOCAL_STORAGE_TOKEN.meta,
              idToken.issuedAtTime
            );
            token = idToken.token;
          }

          if (token) config.headers.Authorization = `Bearer ${token}`;
          else config.headers.Authorization = null;

          if (
            window.location.pathname.toLowerCase() !== "/" &&
            window.location.pathname.toLowerCase() !== "/login" &&
            window.location.pathname.toLowerCase() !== "/forgotpassword" &&
            window.location.pathname.toLowerCase() !== "/signup"
          ) {
            customClaims = await auth.currentUser
              .getIdTokenResult()
              .catch((e) => null);
          }
        }
        return config;
      } catch (e) {
        console.log("error at api.js", e);
      }
    });
}

AttachAuthHeader();

export default config;
