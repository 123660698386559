import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  setReportHeaderData,
  setLinkCounter,
} from "../reducersAndActions/Actions";
import error from "../../src/images/error.jpg";
import data from "../Data_1.json";
import Toc from "../pages/NewToc";
import CompanyMarketSpecific from "../pages/CompanyMarketSpecific";
import ProductAndServicesV2 from "../pages/ProductAndServicesV2";
import CompanyProfileOverview from "../pages/CompanyProfileOverview";
import DisplayStaticPdfs from "../pages/displayPdfs/displaypdfComponent";
import FinalCustomSlide from "../components/CustomSlideComponent/FinalCustomSlide/index";
import {
  config,
  Template1100,
  Template1111,
  Template1000,
  Template1001,
  Template1011,
  Template1101R,
  Template1101C,
  Home,
  Contact,
  Disclaimer,
  ReportOffer,
  StudyAssumption,
  ScopeOfStudyStatic,
  KeyStrategicThemesCEOS,
  ResearchMethodology,
  DecisionMaker,
  SegmentDefination,
  FeedbackAndAudit,
  Sources,
  ScopeStudyRegional,
  ProductAndServices,
  GrowthStrategyMatrix,
  GlobalOverview,
  RecentDevelopment,
  EditableExecutiveSummary,
  StaticExecutiveSummary,
  FeedProduction,
  CompanyProfile,
  RegulatoryFramework,
  RegionOverview,
  ListOfFigures,
  Introduction,
  RegulatoryFrameworkV2,
  ValueChainHubs,
  PrimaryInsights,
  CompetitiveLandscape,
  StaticSlide,
  IndicatorsOne,
  IndicatorsTwo,
  IndicatorsThree,
  IndicatorsFour,
} from "./index";
import CompanyList from "../pages/CompanyList";
import ConsumerBuying from "../pages/ValueChain/Confectionery/consumer_buying/index";

var Base64 = require("js-base64").Base64;
let static_sections = [
  "primary_insights",
  "sample_staticPdf",
  "currency_exchange_rate",
  "glossary_of_terms",
  "static_globalDataPack",
  "global_overview",
  "portor_forces",
  "technological_advancements",
  "global_value_chain",
  "global_marketSize_DROs",
];
const slideMapping = {
  home: Home,
  disclaimer: Disclaimer,
  toc: Toc,
  executive_summary: EditableExecutiveSummary,
  executive_summary_static: StaticExecutiveSummary,
  introduction: Introduction,
  studyAssumptions: StudyAssumption,
  regulatoryFramework: RegulatoryFrameworkV2,
  regulatoryFramework2: RegulatoryFramework,
  valueChain: ValueChainHubs,
  companyProfiles: CompanyProfile,
  company_list: CompanyList,
  keyStrategicThemes: KeyStrategicThemesCEOS,
  scopeofstudystatic: ScopeOfStudyStatic,
  reportoffers: ReportOffer,
  feedproduction: FeedProduction,
  decisionmaker: DecisionMaker,
  globaloverview: GlobalOverview,
  regionoverview: RegionOverview,
  recentdevelopment: RecentDevelopment,
  growthstrategymatrics: GrowthStrategyMatrix,
  appendix1: SegmentDefination,
  appendix2: ProductAndServices,
  sources: Sources,
  feedbackandaudit: FeedbackAndAudit,
  contact: Contact,
  products: ProductAndServicesV2,
  Key_development: RecentDevelopment,
  global_company: CompanyProfileOverview,
  market_company: CompanyMarketSpecific,
  feedback: FeedbackAndAudit,
  formoreinformation: Contact,
  1100: Template1100,
  1000: Template1000,
  1111: Template1111,
  1011: Template1011,
  1001: Template1001,
  "1101r": Template1101R,
  "1101c": Template1101C,
  consumer_buying: ConsumerBuying,
  indicatorSoftOne: IndicatorsOne,
  indicatorSoftTwo: IndicatorsTwo,
  indicatorSoftThree: IndicatorsThree,
  indicatorSoftFour: IndicatorsFour,
};

class FinalReport extends React.Component {
  state = {
    data: {},
    meta: {},
    loading: true,
    sample: false,
    reseller: false,
    domain: "",
    explode: false,
    hasError: false,
    errorMessage: "",
    error: false,
    errorInfo: null,
  };

  static getDerivedStateFromError(error) {
    console.error("Error >> ", error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.error("Error >> ", error);
  }

  async componentDidMount() {
    try {
      // API Call
      const folder = this.props.match.params.folder;
      let url = config.api.base_url;

      // PICK DATA USING FOLDER
      // let response = await fetch(url + "/" + folder + "/data.json");
      // let json = await response.json();

      // PICK DATA FROM MORDOR_DATABASE
      let response = await axios.post(`${url}/api/getSpecificPdfData`, {
        file_name: folder,
      });
      let json = response.data.data;

      // PICK DATA FROM DATA.JSON
      // let json = data;

      if (json.meta) {
        this.setState({ meta: json.meta });
        if (json.meta.domain) {
          this.setState({ domain: json.meta.domain });
        }
        if (json.meta.sample === true) {
          if (json.meta.reseller === true)
            this.setState({ sample: true, reseller: true });
          else this.setState({ sample: true });
        }
        if (json.customSlides && json.customSlides.length > 0) {
          const customSlides = {};
          json.customSlides.forEach((element) => {
            customSlides[element.pageNo] = element;
          });
          this.setState({ customSlides: customSlides });
        }
      }
      // Setting Report Header Data In Redux
      let headerNavigation_data = json.data.map((item) => {
        let header_navigation,
          num = 0;

        if (num === 0 && item.template === "toc") {
          num++;
          header_navigation = item.data.filter((item) => {
            return item.meta.template === "introduction";
          });
          this.props.setReportHeaderData({
            header_navigation,
            report_name: json.reportName,
          });
        }
      });

      if (!json.message) {
        this.setState({ data: json || [], loading: false });
      }
    } catch (error) {
      console.error(error);
    }
  }

  generatePdf = () => {
    try {
      const { data, customSlides } = this.state.data.hasOwnProperty("data")
        ? this.state.data
        : this.state;

      let counter = 0;
      const finalCusSlide = {};
      if (Array.isArray(customSlides) && customSlides.length > 0) {
        customSlides.forEach((element) => {
          finalCusSlide[element.pageNo] = element;
        });
      }
      const renderTestingDiv = (index) => {
        if (finalCusSlide[counter]) {
          counter++; // Increment the counter after rendering

          return (
            <FinalCustomSlide
              key={index}
              index={index}
              data={finalCusSlide[counter - 1]}
              counter={counter}
            />
          );
        }
      };
      if (!data.length || data.length === 0) {
        return <h2>Loading data...</h2>;
      } else if (data.length > 0 && !this.state.loading) {
        let report_info = data.map((item, index) => {
          switch (item.template) {
            case "toc": {
              // logic for generating toc
              let tocs = item.data;
              let super_arr = [];
              let new_arr;

              for (let toc of tocs) {
                super_arr.push(
                  <h3 className="toc_element">
                    <a href={"#" + Base64.encode(toc.meta.section_id)}>
                      {(toc.meta.index ? toc.meta.index + " " : "") + toc.title}
                    </a>
                  </h3>
                );

                function recursive(child_arr, side, index) {
                  let idx_counter = 0;
                  for (let i = 0; i < child_arr.length; i++) {
                    if (child_arr[i].meta.tocVisibility) {
                      if (child_arr[i].children.length) {
                        super_arr.push(
                          <p
                            style={{ paddingLeft: `${side}px` }}
                            className="toc_element"
                          >
                            <a
                              href={
                                "#" +
                                Base64.encode(child_arr[i].meta.section_id)
                              }
                            >
                              {index}.{idx_counter + 1} {child_arr[i].title}
                            </a>
                          </p>
                        );
                        recursive(
                          child_arr[i].children,
                          side + 10,
                          index + "." + (idx_counter + 1)
                        );
                      } else {
                        if (
                          !child_arr[i].title
                            .toLowerCase()
                            .includes("disclaimer") &&
                          child_arr[i].meta.template !== "null"
                        ) {
                          super_arr.push(
                            <p
                              style={{ paddingLeft: `${side}px` }}
                              className="toc_element"
                            >
                              <a
                                href={
                                  "#" +
                                  Base64.encode(child_arr[i].meta.section_id)
                                }
                              >
                                <>
                                  {index}.{idx_counter + 1} {child_arr[i].title}
                                </>
                              </a>
                            </p>
                          );
                        } else {
                          super_arr.push(
                            <p
                              style={{
                                paddingLeft: `${0}px`,
                                textTransform: "unset",
                              }}
                              className="toc_element"
                            >
                              <a>{"*" + child_arr[i].title}</a>
                            </p>
                          );
                        }
                      }
                      idx_counter++;
                    }
                  }
                }

                if (toc.children) {
                  recursive(toc.children, 10, toc.meta.index);
                }
              }

              if (super_arr.length >= 62) {
                new_arr = this.splittingSlides({ data: super_arr }, 62);
                return new_arr.map((item, i) => {
                  counter++;
                  return (
                    <>
                      {" "}
                      <Toc
                        toc_child={item.data}
                        slideNo={counter}
                        data={data[1]}
                      />
                    </>
                  );
                });
              } else {
                counter++;
                return (
                  <>
                    {" "}
                    <Toc
                      toc_child={super_arr}
                      slideNo={counter}
                      data={data[1]}
                    />
                  </>
                );
              }
            }

            case "static_slide": {
              if (item.meta.sample && static_sections.includes(item.type)) {
                return <></>;
              } else if (!item.data) {
                counter++;
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <StaticSlide key={index} data={item} slideNo={counter} />
                  </>
                );
              }
              let static_framework = this.splittingSlides(item, 1);
              return static_framework.map((componentValue) => {
                counter++;
                return (
                  <>
                    {" "}
                    {!item.meta.sample && renderTestingDiv(index)}
                    <StaticSlide
                      key={index}
                      data={componentValue}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }

            case "regulatoryFramework": {
              let regulatoryFramework = this.splittingSlides(item, 1);
              return regulatoryFramework.map((compnentValue, k) => {
                counter++;
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <RegulatoryFrameworkV2
                      data={compnentValue}
                      key={index}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }

            case "regulatoryFramework2": {
              let regulatoryFramework = this.splittingSlides(item, 1);
              return regulatoryFramework.map((compnentValue, k) => {
                counter++;
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <RegulatoryFramework
                      data={compnentValue}
                      key={index}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }

            case "sources": {
              let dataWithNo_source =
                item.data &&
                item.data.filter((sources, i) => {
                  return sources.text !== "no_source" || "";
                });

              let newSourceData = { ...item, data: dataWithNo_source };
              let sourcesSlides = this.splittingSourceSlides(newSourceData);

              // this.splittingSlides(newSourceData, 10);
              let sourceIndexCounter = 1;
              return sourcesSlides.map((sourceValue, index) => {
                counter++;
                sourceValue = {
                  ...sourceValue,

                  sourceCountStart: sourceIndexCounter,
                };
                sourceIndexCounter += sourceValue.data.length;

                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <Sources data={sourceValue} key={index} slideNo={counter} />
                  </>
                );
              });
            }

            case "competitive_landscape_overview": {
              let company_landscape = [],
                char_counter = 0,
                array = [];
              for (let sub_item of item.data.global_data) {
                console.log(sub_item["key_products"]);
                char_counter += Math.ceil(
                  Number(sub_item["key_products"]?.length / 38)
                );
                array.push(sub_item);
                if (char_counter >= 30) {
                  if (char_counter > 50) {
                    let duplicateArray = JSON.parse(JSON.stringify(array));
                    duplicateArray.pop();
                    company_landscape.push(
                      companyLandscapeRender(item, duplicateArray)
                    );
                    let lastElement = array[array.length - 1];
                    company_landscape.push(
                      companyLandscapeRender(item, [lastElement])
                    );
                  } else {
                    company_landscape.push(companyLandscapeRender(item, array));
                  }

                  char_counter = 0;
                  array = [];
                }

                char_counter += 2;
              }
              if (array.length !== 0) {
                company_landscape.push(companyLandscapeRender(item, array));
                char_counter = 0;
                array = [];
              }
              function companyLandscapeRender(item, arr) {
                let obj = {};
                obj = { ...item };
                obj["data"] = {
                  global_data: [],
                };
                obj["data"]["global_data"] = arr;
                return obj;
              }

              return company_landscape.map((sourceValue, index) => {
                counter++;
                sourceValue = {
                  ...sourceValue,
                  sourceCountStart: 14 * index + 1,
                };
                sourceValue["data"]["headers_order"] = item.data.headers_order;
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <CompetitiveLandscape
                      data={sourceValue}
                      key={index}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }
            case "company_list": {
              let splittingCompanyList = (data, divider) => {
                try {
                  let arr = [],
                    obj = {};
                  for (
                    let i = 0;
                    i < data.data.company_list?.length;
                    i = i + divider
                  ) {
                    obj = { ...data };
                    obj["data"] = {
                      company_list: [],
                    };
                    for (let j = i; j < i + divider; j++) {
                      if (data.data.company_list[j])
                        obj["data"]["company_list"].push(
                          data.data.company_list[j]
                        );
                      else break;
                    }
                    arr.push(obj);
                  }
                  return arr;
                } catch (err) {
                  console.log("err", err);
                }
              };
              let companyList = splittingCompanyList(item, 18);
              return companyList.map((val, index) => {
                counter++;
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <CompanyList data={val} key={index} slideNo={counter} />
                  </>
                );
              });
            }
            case "listOfFighure": {
              let figureSlides = this.splittingSlides(item, 20);
              return figureSlides.map((sourceValue, index) => {
                counter++;
                sourceValue = {
                  ...sourceValue,
                  sourceCountStart: 20 * index + 1,
                };
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <ListOfFigures
                      data={sourceValue}
                      key={index}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }

            case "scopeOfStudy": {
              let scopeOfStudySlides = this.splittingSlides(item, 5);
              return scopeOfStudySlides.map((sourceValue, index) => {
                counter++;
                sourceValue = {
                  ...sourceValue,
                  sourceCountStart: 7 * index + 1,
                };
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <ScopeStudyRegional
                      data={sourceValue}
                      key={index}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }

            case "researchMethodology": {
              if (!item.data) {
                counter++;
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <ResearchMethodology
                      key={index}
                      data={item}
                      slideNo={counter}
                    />
                  </>
                );
              }
              let researchMethodology = this.splittingSlides(item, 1);
              return researchMethodology.map((sourceValue, index) => {
                counter++;
                sourceValue = {
                  ...sourceValue,
                  sourceCountStart: 1 * index + 1,
                };
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <ResearchMethodology
                      data={sourceValue}
                      key={index}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }

            case "primary_insights": {
              let primary_insights = this.splittingSlides(item, 1);
              return primary_insights.map((sourceValue, index) => {
                counter++;
                sourceValue = {
                  ...sourceValue,
                  sourceCountStart: 1 * index + 1,
                };
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <PrimaryInsights
                      data={sourceValue}
                      key={index}
                      slideNo={counter}
                    />
                  </>
                );
              });
            }
            case "introduction": {
              let introduction_data = [item];
              if (item?.data?.length > 30) {
                introduction_data = this.splittingSlides(item, 30);
              }
              return introduction_data.map((sourceValue, index) => {
                counter++;
                sourceValue = {
                  ...sourceValue,
                  sourceCountStart: 1 * index + 1,
                };
                return (
                  <>
                    {" "}
                    {!item.meta.sample && renderTestingDiv(index)}
                    <Introduction
                      data={sourceValue}
                      key={index}
                      slideNo={counter}
                      hideFrame={item?.data?.length > 30}
                    />
                  </>
                );
              });
            }
            case "valueChain": {
              if (item.multi_urls && item.multi_urls.length > 0) {
                return item.multi_urls.map((child_item, child_index) => {
                  counter++;
                  let new_obj = { ...item };
                  new_obj["multi_urls"] = [child_item];
                  new_obj["item_num"] = child_index;
                  new_obj["data"] = [item["data"][child_index]];

                  return (
                    <>
                      {!item.meta.sample && renderTestingDiv(index)}
                      <ValueChainHubs
                        data={new_obj}
                        key={index}
                        slideNo={counter}
                      />
                    </>
                  );
                });
              } else {
                counter++;
                return (
                  <>
                    {!item.meta.sample && renderTestingDiv(index)}
                    <ValueChainHubs data={item} key={index} slideNo={counter} />
                  </>
                );
              }
            }

            case "companyProfile1":
            case "companyProfile2": {
              let company_prfles = this.splittingCompanyProfiles(item);

              return (
                <>
                  {company_prfles &&
                    company_prfles.data.map((slideData, i) => {
                      let key = Object.keys(slideData).filter((keyvalue, j) => {
                        return (
                          keyvalue === "products" ||
                          keyvalue === "Key_development" ||
                          keyvalue === "global_company" ||
                          keyvalue === "market_company"
                        );
                      });
                      let Slide;

                      if (key[0] === "Key_development") {
                        Slide = slideMapping[key[0]];
                        let new_prdct_obj = { ...slideData };
                        return new_prdct_obj.Key_development.length
                          ? new_prdct_obj.Key_development.map((keySlide, k) => {
                              counter++;
                              let newkey_slide = { ...new_prdct_obj };
                              newkey_slide["Key_development"] = keySlide;

                              return (
                                <>
                                  {!item.meta.sample && renderTestingDiv(index)}
                                  <Slide
                                    data={newkey_slide}
                                    key={index}
                                    slideNo={counter}
                                  />
                                </>
                              );
                            })
                          : null;
                      } else if (key[0] === "products") {
                        Slide = slideMapping[key[0]];
                        let new_prdct_obj = { ...slideData };
                        // let { products: _, ...other } = new_prdct_obj;
                        let productSlides = [];
                        for (let i = 1; i < slideData.products.length; i++) {
                          let obj = { ...new_prdct_obj };
                          let new_item = [slideData.products[0]];
                          new_item.push(slideData.products[i]);

                          obj["data"] = new_item;
                          productSlides.push(obj);
                        }
                        return productSlides.length
                          ? productSlides.map((productSlide, k) => {
                              counter++;
                              let newprodct_slide = { ...productSlide };
                              newprodct_slide["products"] = productSlide.data;

                              return (
                                <>
                                  {!item.meta.sample && renderTestingDiv(index)}
                                  <Slide
                                    data={newprodct_slide}
                                    key={index}
                                    slideNo={counter}
                                  />
                                </>
                              );
                            })
                          : null;
                      } else {
                        Slide = slideMapping[key[0]];
                        counter++;
                        if (
                          slideData.global_company !== undefined ||
                          slideData.market_company !== undefined
                        ) {
                          return (
                            <>
                              {!item.meta.sample && renderTestingDiv(slideData)}
                              <Slide
                                data={slideData}
                                template={slideData.template}
                                key={index}
                                slideNo={counter}
                              />
                            </>
                          );
                        } else {
                          return null;
                        }
                      }
                    })}
                </>
              );
            }

            case "none":
            case "null": {
              return null;
            }

            default: {
              // const Component = slideMapping[item.template];

              const slide_templates =
                item.template === "global_data_pack"
                  ? item.data &&
                    item.data.map((item) => {
                      return item.multitemplate_names.map(
                        (item) => item.template_name
                      );
                    })
                  : item.data &&
                    item.data.multitemplate_names &&
                    item.data.multitemplate_names.map(
                      (itm) => itm.template_name
                    );

              const Components = Array.isArray(slide_templates)
                ? item.template === "global_data_pack"
                  ? slide_templates.flat().map((item) => slideMapping[item])
                  : slide_templates.map((item) => slideMapping[item])
                : slideMapping[item.template];

              if (Array.isArray(Components)) {
                return Components.map((Component, idx) => {
                  counter++;
                  return (
                    <>
                      {!item.meta.sample && renderTestingDiv(index)}
                      <Component
                        data={item}
                        key={index}
                        template={item.template}
                        template_title={item.title}
                        idx={idx}
                        slideNo={counter}
                      />
                    </>
                  );
                });
              } else {
                counter++;
                return (
                  Components && (
                    <>
                      {/* {!item.meta.sample && renderTestingDiv(index)} */}
                      <Components data={item} key={index} slideNo={counter} />
                    </>
                  )
                );
              }
            }
          }
        });

        if (this.state.reseller) {
          //let about_us_without_footer = require(`../pages/displayPdfs/SamplesPdfByHub/${this.state.domain}/about_us_without_footer.pdf`);
          report_info.push(
            <DisplayStaticPdfs file={this.state.meta["static_pdf_url"]} />
          );
          report_info.push(
            <Disclaimer data={{ meta: { sample: true, reseller: true } }} />
          );
        } else if (this.state.sample && this.state.meta?.static_pdf_url) {
          //let about_us_with_footer = require(`../pages/displayPdfs/SamplesPdfByHub/lubricants/about_us_with_footer.pdf`);
          report_info.push(
            <DisplayStaticPdfs file={this.state.meta["static_pdf_url"]} />
          );
          report_info.push(<Disclaimer data={{ meta: { reseller: false } }} />);
          report_info.push(<Contact />);
        }

        return report_info;
      }
    } catch (err) {
      this.setState({ hasError: true, errorMessage: err });
    }
  };

  splittingCompanyProfiles = (profiles) => {
    let obj = {};
    let { data: _, ...new_obj } = profiles;
    obj["data"] = [
      { global_company: profiles.data.global_company, ...new_obj },
      { market_company: profiles.data.market_company, ...new_obj },
      { products: profiles.data.products, ...new_obj },
      { Key_development: profiles.data.Key_development, ...new_obj },
    ];

    return obj;
  };

  splittingSlides = (data, divider) => {
    try {
      let arr = [],
        obj = {};
      for (let i = 0; i < data.data?.length; i = i + divider) {
        obj = { ...data };
        obj["data"] = [];
        for (let j = i; j < i + divider; j++) {
          if (data.data[j]) obj["data"].push(data.data[j]);
          else break;
        }
        arr.push(obj);
      }
      return arr;
    } catch (err) {
      console.log("err", err);
    }
  };

  splittingSourceSlides = (data) => {
    let elements = [...data.data],
      counter = 0,
      new_arr = [],
      child_arr = [];

    for (let [index, item] of elements.entries()) {
      let newData = { ...data };

      let current_lines = Math.ceil(Number(item.text.length / 185));

      if (counter + current_lines >= 20) {
        newData.data = child_arr;
        new_arr.push(newData);
        child_arr = [];
        child_arr.push(item);
        counter = current_lines;
      } else if (counter + current_lines < 20 && current_lines < 2) {
        child_arr.push(item);
        counter += current_lines;
      } else {
        child_arr.push(item);
        counter += current_lines;
      }
    }

    if (child_arr.length > 0) {
      let newData = { ...data };
      newData.data = child_arr;
      new_arr.push(newData);
    }

    return new_arr;
  };

  stringDivider = (data, divider) => {
    let counter = 0,
      arr = [],
      startAt = 0;
    for (let [index, item] of data.data.entries()) {
      counter +=
        Math.ceil(item.text.length / 141) > 1
          ? Math.ceil(item.text.length / 141) - 0.5
          : 1;
      if (counter >= divider) {
        splitArrayFunction(data, startAt, index - 1);
        startAt = index;
        counter = 0;
      }
    }
    if (counter !== 0) {
      splitArrayFunction(data, startAt, data.data.length - 1);
    }
    function splitArrayFunction(data, startAt, num) {
      let obj = { ...data };
      obj["data"] = [];

      for (let j = startAt; j <= num; j++) {
        if (data.data[j]) {
          obj["data"].push(data.data[j]);
        } else break;
      }
      arr.push(obj);
    }

    return arr;
  };

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <img
            width={500}
            height={400}
            style={{ display: "flex", margin: "auto" }}
            src={error}
            alt="error"
          />
          <br />
          <h1 style={{ textAlign: "center" }}>
            Something was wrong:{this.constructor.name}
          </h1>
          <br />
          <div style={{ textAlign: "center" }}>
            <h4 style={{ color: "red" }}>
              {this?.state?.error && this?.state?.error?.toString()}
            </h4>
            <br />
            <div>
              <p
                className="text-left"
                style={{
                  whiteSpace: "break-spaces",
                  overflowWrap: "anywhere",
                  color: "red",
                }}
              >
                {this?.state?.errorInfo &&
                  this?.state?.errorInfo?.componentStack?.toString()}
              </p>
            </div>
          </div>
          {/* </details> */}
        </div>
      );
    }
    return <div>{this.generatePdf()}</div>;
  }
}

function mapStateToPros(state) {
  return {
    REPORT_HEADER_DATA: state.REPORT_HEADER_DATA,
    ANALYSIS_LINK_COUNTER: state.ANALYSIS_LINK_COUNTER,
  };
}

const mapDispatchToProps = {
  setReportHeaderData,
  setLinkCounter,
};

export default connect(mapStateToPros, mapDispatchToProps)(FinalReport);
