import React from "react";
import Heading from "../../../Heading";
import Graph from "../../../../Graphs/Graphs";
import Subheading from "../../../Subheading";
import { Container, Row, Column, FullColumn } from "../style";
import Analysis from "../../../Analysis";
function FinalCusTemTwo(props) {
  const { data } = props;
  return (
    <Container formInterface={true} style={{ height: "620px" }}>
      <Heading heading={data.slideHeading} />
      <Row>
        <Graph
          chartHeadingGap={10}
          wrapHeadingWidth={400}
          yHeadingMargin={25}
          headers={data.graphs[0].heading}
          data={data.graphs[0].data}
          graph_config={data.graphs[0].graph_config}
          width={1080}
          totalData={data.graphs[0].data}
          graphType={data.graphs[0].graphType}
          dataType={data.graphs[0].dataType}
          yAxisPercentage={data.graphs[0].YAxisPercentage}
          dynamiclegends={
            data.graphs[0].legends ? data.graphs[0].legends : ["Value", "YOY"]
          }
          min_SYAxis={
            data.graphs[0].min_SYAxis ? data.graphs[0].min_SYAxis : false
          }
          graph_meta={
            data.graphs[0].graph_meta ? data.graphs[0].graph_meta : {}
          }
          height={266}
          slideNo
        />
      </Row>

      <FullColumn>
        <Subheading subheading={data.analysis.heading} />
        <Analysis
          trends={data.analysis.data}
          edit_mode={false}
          section_id={
            data.section_id
              ? data.section_id
              : data.meta.section_id
                ? data.meta.section_id
                : data.analysis.heading
          }
        />
      </FullColumn>
    </Container>
  );
}

export default FinalCusTemTwo;
