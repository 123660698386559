import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Page, Container } from "./style";
import configApi from "../../../config/Api";
import CustomTempOne from "./CustomTempOne";
import CustomTempTwo from "./CustomTempTwo";
import CustomTempThree from "./CustomTempThree.js";
import CustomTempFour from "./CustomTempFour.js";
import CustomTempFive from "./CustomTempFive.js";
import CustomTempSix from "./CustomTempSix.js";
import CustomTempSeven from "./CustomTempSeven.js";
import { Button, notification, Select, Spin } from "antd";
import { EditFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

import axios from "axios";

const { Option } = Select;

function getToken() {
  return window.localStorage.getItem("token");
}
const list = [
  {
    name: "Graph and Analysis",
    id: "Temp1",
    logo: "https://s3.mordorintelligence.com/lorien-files/live/CP/1732719021900-customsldiet1logox.png.png",
  },
  {
    name: "Top Graph and Bottom Analysis",
    id: "Temp2",
    logo: "https://s3.mordorintelligence.com/lorien-files/live/CP/1732721214234-customsldiet2logox.png",
  },
  {
    name: "Only Analysis",
    id: "Temp3",
    logo: "https://s3.mordorintelligence.com/lorien-files/live/CP/1732719060986-customsldiet3logox.png.png",
  },
  {
    name: "Image",
    id: "Temp4",
    logo: "https://s3.mordorintelligence.com/lorien-files/live/CP/1732719060986-customsldiet4logox.png.png",
  },
  {
    name: "4 Graphs",
    id: "Temp5",
    logo: "https://s3.mordorintelligence.com/lorien-files/live/CP/1733394288987-4g.png",
  },
  {
    name: "2 Graphs and Analysis",
    id: "Temp6",
    logo: "https://s3.mordorintelligence.com/lorien-files/live/CP/1733394288984-2g1a.png",
  },
  {
    name: "Regulatory Framework",
    id: "Temp7",
    logo: "https://s3.mordorintelligence.com/lorien-files/live/CP/1733394288988-rf.png",
  },
];

const TEMPLATE_COMPONENTS = {
  Temp1: CustomTempOne,
  Temp2: CustomTempTwo,
  Temp3: CustomTempThree,
  Temp4: CustomTempFour,
  Temp5: CustomTempFive,
  Temp6: CustomTempSix,
  Temp7: CustomTempSeven,
};

const TemplateRenderer = ({
  template_name,
  data,
  editMode,
  saveLoading,
  setSelectedCustomSlideData,
  onSave,
}) => {
  const TemplateComponent = TEMPLATE_COMPONENTS[template_name];
  return TemplateComponent ? (
    <TemplateComponent
      saveLoading={saveLoading}
      data={data}
      onSave={onSave}
      editMode={editMode}
      fromInterface={true}
      setSelectedCustomSlideData={setSelectedCustomSlideData}
    />
  ) : null;
};
function CustomSlideTemplate(props) {
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Check if a specific query (e.g., "query") exists
  const hasQuery = searchParams.has("edit");

  const {
    setSelectedCustomSlideData,
    selectedRow,
    edit,
    setEdit,
    setOpenCreate,
    fetchList,
  } = props;
  const [hubData, setHubData] = React.useState([]);

  const [data, setData] = useState({
    template_name: "",
    hub_id: "",
  });

  useEffect(() => {
    if (hasQuery) {
      setPageLoad(true);
      axios
        .get(
          `${configApi.api.base_url}/api/custom/slide-single?id=${searchParams.get("id")}`,

          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((res) => {
          setPageLoad(false);
          console.log(res, "---");
          setData(res.data);
        });
    }
  }, [hasQuery]);
  const getHubList = async () => {
    setLoading(true);
    if (hubData.length == 0) {
      let full_access = false;
      await axios
        .get(
          `${configApi.api.base_url}/api/hubs/list?full_access=${full_access}`
        )
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const handleChangeTemplate = (value) => {
    setData({ ...data, template_name: value });
  };

  const onSave = async ({ id, finalData }) => {
    try {
      setLoading(true);

      if (!data.hub_id || !finalData.slideHeading) {
        notification.error({
          message: "Failure",
          description: "hub id or slide heading is missing",
        });
        setLoading(false);
        return;
      }
      if (id === "new") {
        await axios.post(
          `${configApi.api.base_url}/api/custom/slide`,
          {
            ...finalData,
            hub_id: data.hub_id,
            template_name: data.template_name,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        setLoading(false);
        setOpenCreate(false);
        setEdit(false);
      } else {
        await axios.patch(
          `${configApi.api.base_url}/api/custom/slide?id=${id}`,
          {
            id,
            data: {
              ...finalData,
              hub_id: data.hub_id,

              template_name: data.template_name,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        setLoading(false);
        setOpenCreate(false);
        setEdit(false);
      }
      await fetchList();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const handleHubChange = async (value) => {
    setData((p) => {
      return { ...p, hub_id: value };
    });
  };
  React.useEffect(() => {
    getHubList();
  }, []);
  return pageLoad ? (
    <div
      style={{
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}>
      <Spin size="large" />
    </div>
  ) : (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          margin: "10px",
        }}>
        <Select
          disabled={hasQuery}
          size={"large"}
          style={{
            display: "block",
            width: "200px",
          }}
          id="selectHub"
          placeholder="select"
          value={data.hub_id}
          loading={loading}
          maxLength=""
          onChange={handleHubChange}
          options={hubData.map((item, index) => ({
            label: item.name,
            value: item.id,
          }))}
          autoFocus={true}
        />
        <Select
          size={"large"}
          placeholder="Select Template"
          optionFilterProp="children"
          showSearch
          // disabled={!data.hub_id}
          onChange={handleChangeTemplate}
          value={data?.template_name}
          style={{
            width: "100%",
            height: "auto", // Ensure height is adjustable
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }>
          {list.map((item) => (
            <Option key={item.id} value={item.id}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontWeight: "bold",
                }}>
                <img
                  style={{
                    width: "35px", // Reduce if it overflows
                    height: "35px",
                    border: "1px solid #00000057",
                    borderRadius: "8px",
                  }}
                  src={item.logo}
                  alt={item.name}
                />
                <div>{item.name}</div>
              </div>
            </Option>
          ))}
        </Select>
      </div>
      <Page className="page">
        <TemplateRenderer
          template_name={data.template_name}
          data={data}
          editMode={hasQuery}
          setSelectedCustomSlideData={setSelectedCustomSlideData}
          onSave={onSave}
          saveLoading={loading}
        />
      </Page>
    </>
  );
}

function mapStateToPros(state) {
  return {
    TOC_DATA: state.TOC_DATA,
  };
}

export default connect(mapStateToPros, null)(CustomSlideTemplate);
