import React, { useEffect, useState } from "react";
import { Column, Row } from "./style";

import Graph from "../../../Graphs/Graphs";

import { Button, Select } from "antd";
import DataModal from "./DataModal";
import { defaultAnalysisCustomSlide } from "../../../constants";
import CustomAnalysis from "../commonFunctions/CustomAnalysis";
const { Option } = Select;

const list = [
  { name: "pie", id: "pie" },
  { name: "barline", id: "barline" },
];

function CustomTempSix(props) {
  const { data, editMode, onSave, saveLoading } = props;
  const { graphs = [], analysis, slideHeading, _id = null } = data;
  const [showDataModal, setShowDataModal] = useState(false);
  const [selectGraphIndex, setSelectGraphIndex] = useState(null);
  const [tempConfig, setTempConfig] = useState({
    slideHeading,
    analysis: analysis ? analysis : defaultAnalysisCustomSlide,
    graphs:
      graphs && graphs.length > 0
        ? graphs
        : Array(2)
            .fill(null)
            .map((_, i) => graphs[i] || {}),

    analysis: analysis ? analysis : defaultAnalysisCustomSlide,
  });
  const handleChangeGraph = (val, i) => {
    setTempConfig((prev) => {
      let temp = { ...prev };
      if (i || i === 0) {
        temp.graphs[i] = { ...temp.graphs[i], graphType: val };
      }
      return temp;
    });
  };
  const addData = (data) => {
    setTempConfig((prev) => {
      const newGraphs = [...prev.graphs];
      newGraphs[selectGraphIndex] = { ...newGraphs[selectGraphIndex], data };
      return { ...prev, graphs: newGraphs };
    });
  };
  useEffect(() => {
    console.log(tempConfig, "tempConfig");
  }, [tempConfig]);

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Row>
          {" "}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: "10px",
            }}
          >
            <input
              placeholder="slide heading"
              style={{ width: "100%" }}
              value={tempConfig.slideHeading}
              onChange={(e) =>
                setTempConfig((p) => {
                  return { ...p, slideHeading: e.target.value };
                })
              }
            />
          </div>
        </Row>
        <Row>
          {[0, 1].map((graphNo) => {
            return (
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    width: "100%",
                    alignItems: "flex-end",
                    gap: "10px",
                  }}
                  className="uploadsection"
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <p className="exportLabels">
                      <span className="mandatory">*</span>Select a Graph Type
                    </p>
                    <Select
                      placeholder={`Select ${graphNo + 1} Graph`}
                      optionFilterProp="children"
                      showSearch
                      onChange={(val) => handleChangeGraph(val, graphNo)}
                      value={tempConfig.graphs[graphNo]?.graphType}
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {list.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <Button
                      disabled={
                        tempConfig.graphs[graphNo]?.graphType ? false : true
                      }
                      onClick={() => {
                        setSelectGraphIndex(graphNo);
                        setShowDataModal(true);
                      }}
                    >
                      Add Data
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  <input
                    style={{
                      width: "100%",
                    }}
                    placeholder={`Graph ${graphNo + 1} heading`}
                    value={tempConfig.graphs[graphNo]?.heading}
                    onChange={(e) =>
                      setTempConfig((prev) => {
                        const newGraphs = [...prev.graphs];
                        newGraphs[graphNo] = {
                          ...newGraphs[graphNo],
                          heading: e.target.value,
                        };
                        return { ...prev, graphs: newGraphs };
                      })
                    }
                  />
                </div>
                <Graph
                  chartHeadingGap={10}
                  wrapHeadingWidth={400}
                  yHeadingMargin={25}
                  headers={tempConfig.graphs[graphNo]?.heading}
                  data={tempConfig.graphs[graphNo]?.data}
                  graph_config={tempConfig.graphs[graphNo]?.graph_config}
                  width={500}
                  totalData={tempConfig.graphs[graphNo]?.data}
                  graphType={tempConfig.graphs[graphNo]?.graphType}
                  dataType={tempConfig.graphs[graphNo]?.dataType}
                  yAxisPercentage={tempConfig.graphs[graphNo]?.YAxisPercentage}
                  dynamiclegends={
                    tempConfig.graphs[graphNo]?.legends
                      ? tempConfig.graphs[graphNo]?.legends
                      : ["Value", "YOY"]
                  }
                  min_SYAxis={
                    tempConfig.graphs[graphNo]?.min_SYAxis
                      ? tempConfig.graphs[graphNo]?.min_SYAxis
                      : false
                  }
                  graph_meta={
                    tempConfig.graphs[graphNo]?.graph_meta
                      ? tempConfig.graphs[graphNo]?.graph_meta
                      : {}
                  }
                  height={266}
                  slideNo
                />
              </Column>
            );
          })}
        </Row>
        <Row style={{ display: "flex", flexDirection: "column" }}>
          <input
            placeholder="Analysis heading"
            value={tempConfig.analysis.heading}
            onChange={(e) =>
              setTempConfig((p) => {
                return {
                  ...p,
                  analysis: {
                    ...p.analysis,
                    heading: e.target.value,
                  },
                };
              })
            }
          />

          <CustomAnalysis
            trends={tempConfig.analysis.data}
            setTempConfig={setTempConfig}
            edit_mode={true}
            section_id={"data.section_id"}
          />
        </Row>
      </div>
      <div>
        {" "}
        <Button
          type="primary"
          loading={saveLoading}
          disabled={saveLoading}
          onClick={() => {
            onSave({
              id: editMode && _id ? _id : "new",
              finalData: {
                ...tempConfig,
                graphs: tempConfig.graphs,
                analysis: tempConfig.analysis,
              },
            });
          }}
        >
          Save
        </Button>
      </div>
      {showDataModal && selectGraphIndex !== null && (
        <DataModal
          addData={addData}
          graphType={tempConfig.graphs[selectGraphIndex]?.graphType}
          showDataModal={showDataModal}
          setShowDataModal={() => {
            setSelectGraphIndex(null);
            setShowDataModal(false);
          }}
          selectGraphIndex={selectGraphIndex}
        />
      )}
    </div>
  );
}

export default CustomTempSix;
