import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, Modal, notification, Select } from "antd";
import axios from "axios";
import config from "../../config/Api";

const openNotification = (msg, type) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Data Deleted successful.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to delete data.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.error(args);
  } else if (type) {
    args = {
      message: type,
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification[type](args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification.warning(args);
  }
};

const CreateCustomReportModal = ({
  open,
  setOpenCreateModal,
  hubData,
  setCustomReportData,
  selected_hub_id,
}) => {
  const [hubId, setHubId] = useState(null);
  const [reportsData, setReportsData] = useState([]);
  const [onGoingApiCall, setOnGoingApiCall] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isForClient, setIsForClient] = useState(false);
  const [clientEmail, setClientEmail] = useState([]);
  const [variantName, setVariantName] = useState(null);

  useEffect(() => {
    if (hubData.length) {
      setLoading(false);
      setOnGoingApiCall(false);
    }
  }, [hubData]);

  const closeModal = () => {
    setOpenCreateModal(false);
    clearFields();
  };

  function clearFields() {
    setHubId(null);
    setReportsData([]);
    setSelectedReport(null);
    setIsForClient(false);
    setClientEmail([]);
    setVariantName(null);
  }

  const handleReportChange = (reportObj) => {
    setSelectedReport(reportObj);
    setVariantName(`V1 - ${reportObj.label}`);
  };

  const handleHubChange = async (hub_id) => {
    try {
      setHubId(hub_id);
      setLoading(true);
      setOnGoingApiCall(true);
      const data = await axios.get(
        `${config.api.base_url}/api/custom/get-dropDown-reportsList?hub_id=${hub_id}`
      );
      setReportsData(data.data);
    } catch (error) {
      console.log("ERROR OCCURED while fetching reports", error);
      openNotification("Unable to fetch reports. Please try again.", "error");
    }
    setLoading(false);
    setOnGoingApiCall(false);
  };

  function getToken() {
    return window.localStorage.getItem("token");
  }

  const handleCreateVariant = async () => {
    try {
      setLoading(true);
      setOnGoingApiCall(true);
      if (
        !hubId ||
        !selectedReport ||
        !variantName ||
        (isForClient && !clientEmail?.length)
      ) {
        openNotification("Required fields are missing.", "error");
        return;
      }

      let payload = {
        hub_id: hubId,
        report_id: selectedReport.report_id,
        variant_name: variantName,
        client_email: clientEmail,
      };

      const createVariant = await axios.post(
        `${config.api.base_url}/api/custom/report`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!createVariant.data.success) {
        openNotification(createVariant.data.message, "warning");
      } else {
        if (selected_hub_id == hubId) {
          setCustomReportData((prev) => [...prev, createVariant.data.data]);
        }
        openNotification("Variant Created successfully!", "success");
      }
      closeModal();
    } catch (error) {
      console.log("ERROR OCCURED", { error });
      openNotification(
        error?.response?.data?.message ||
          error.message ||
          "Something went wrong",
        "error"
      );
    }
    setLoading(false);
    setOnGoingApiCall(false);
  };

  return (
    <Modal
      title="Create Custom Report"
      open={open}
      onCancel={closeModal}
      footer={
        <div>
          <Button
            disabled={onGoingApiCall}
            // loading={loading}
            type="primary"
            danger
            onClick={closeModal}>
            Discard
          </Button>
          <Button
            disabled={onGoingApiCall}
            loading={onGoingApiCall}
            type="primary"
            onClick={handleCreateVariant}>
            Create
          </Button>
        </div>
      }>
      <div className="options-container-custom-report">
        <div className="dropdownContainer">
          <label
            htmlFor="selectHub"
            style={{
              display: "block",
              margin: "10px 0 4px",
            }}>
            Select Hub :
          </label>
          <Select
            disabled={onGoingApiCall}
            loading={loading}
            style={{
              width: "100%",
            }}
            id="selectHub"
            placeholder="select"
            value={hubId}
            onChange={handleHubChange}
            options={hubData.map((item, index) => ({
              label: item.name,
              value: item.id,
            }))}
            autoFocus={open}
          />
        </div>

        <div className="dropdownContainer">
          <label
            htmlFor="selectReport"
            style={{
              display: "block",
              margin: "10px 0 4px",
            }}>
            Select Report :
          </label>
          <Select
            id="selectReport"
            disabled={onGoingApiCall}
            loading={loading}
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a report"
            value={selectedReport?.label || null}
            onChange={(_value, obj) => handleReportChange(obj)}
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={[
              ...reportsData?.map((report) => ({
                label: report.title,
                value: report.title,
                report_id: report.report_id,
              })),
              {
                label:
                  "Required report not in the list? Please purge and regenerate the report.",
                value: "not_in_list", // A unique value
                disabled: true, // Make this option non-selectable
              },
            ]}
          />
        </div>
      </div>

      {selectedReport && selectedReport.label ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "flex-start",
            gap: "10px",
            paddingTop: "10px",
          }}>
          <h3
            style={{
              textAlign: "center",
              margin: "10px 0",
              textDecoration: "2px underline",
              textUnderlineOffset: "5px",
            }}>
            {selectedReport?.label || ""}
          </h3>

          <Checkbox
            onChange={(e) => setIsForClient(e.target.checked)}
            checked={isForClient}>
            Show Variant on synapse?
          </Checkbox>

          {isForClient ? (
            <div>
              <label>Please enter the client email id</label>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                title="Client Email"
                placeholder="enter email"
                value={clientEmail}
                onChange={(value) => setClientEmail(value)}
                options={
                  clientEmail?.length
                    ? clientEmail.map((item) => ({
                        label: item,
                        value: item,
                      }))
                    : []
                }
              />
            </div>
          ) : null}

          <div>
            <label>Variant Name</label>
            <Input
              value={variantName}
              title="Report variant Name"
              placeholder="enter variant name"
              onInput={(e) => setVariantName(e.target.value)}
            />
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export default CreateCustomReportModal;
